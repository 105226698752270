export interface RequireAuthProps {
  allowRoles: Array<string>;
}
export interface Config<TToken, TLoginParams> {
  sendLogin: (loginParams: TLoginParams) => Promise<TToken>;
  retry: (failCount: number, error: any) => boolean;
  queryKey?: string;
}

export interface Credentials {
  email: string;
  password: string;
  event?: React.FormEvent<HTMLFormElement>;
}
export interface CredentialsForForgetPassword {
  email: string;
  event?: React.FormEvent<HTMLFormElement>;
}
export interface CredentialsForOTPForm {
  password: string;
  event?: React.FormEvent<HTMLFormElement>;
}
export interface CredentialsForConfirmPassword {
  password: string;
  confirm_password: string;
  event?: React.FormEvent<HTMLFormElement>;
}
export interface OAuthToken {
  access_token: string;
  refresh_token: string;
  expires_in: number;
  refresh_token_expires_in: number;
  token_type?: string;
  scope?: string;
}

export interface Token {
  authToken: string;
  roles: Array<string>;
  time:Date;
}

export type StoreValue = {
  authToken: string;
  roles: Array<string>;
  time:Date;
};

export const RoleNames = {
  superAdmin: "SUPER_ADMIN",
  admin: "ADMIN",
  finance: "FINANCE",
};

export const OLD_KUNDE_DASHBOARD = "/dashboard"