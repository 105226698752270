import { AppError, getErrorMessage } from "../../App/errorMessageUtils";
import {
  Formik,
  FormikConfig,
  Form as FormikForm,
  FormikHelpers,
  FormikValues,
} from "formik";

import Button from "@mui/material/Button";
// import { Button } from "../Buttons/Buttons";
import { Loader } from "../Loader/Loader";
import { useLoader } from "../../components/GlobelLoader";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export type SubmitHandler<FormData> = (
  values: FormData,
  formikHelpers?: FormikHelpers<FormData>
) => Promise<FormData> | void;

export interface FormProps<FormData> extends FormikConfig<FormData> {
  title?: string;
  onCancel?: () => void;
  buttonText?: string;
  errorMessage?: string;
  onSubmit: SubmitHandler<FormData>;
  intro?: React.ReactNode;
  testId?: string;
  buttonContainer: string;
  onChange?: () => void;
}

export interface PublicFormProps<FormData>
  extends Omit<FormProps<FormData>, "initialValues"> {
  initialValues?: FormData;
}

export const Form = <FormData,>({
  title,
  initialValues,
  validationSchema,
  onSubmit,
  children,
  onChange,
  buttonText,
  onCancel,
  errorMessage,
  intro,
  testId,
  buttonContainer,
}: React.PropsWithChildren<FormProps<FormikValues & FormData>>) => {
  const { t } = useTranslation();

  const [errorMessages, setErrorMessages] = useState<string[] | null>();
  const { open } = useLoader();
  const handleSubmit: (
    values: FormikValues & FormData,
    actions?: FormikHelpers<FormikValues & FormData>
  ) => void = async (data, actions) => {
    try {
      //setErrorMessages(null);
      const newFormData = await onSubmit(data);
      actions?.resetForm(newFormData ? { values: newFormData } : undefined);
      // actions.setSubmitting(false);
    } catch (error: any) {
      if (
        error.response &&
        error.response.data.errorCodes &&
        error.response.data.errorCodes.length
      ) {
        const errorMessages = error.response.data.errorCodes.map(
          (code: AppError) => getErrorMessage(code)
        );
        setErrorMessages(errorMessages);
      } else {
        setErrorMessages([t("error.something-has-gone-wrong")]);
      }
    }
  };
  //  ((values: FormikValues & FormData, formikHelpers: FormikHelpers<FormikValues & FormData>) => void | Promise<...>) & ((values: any, actions: any) => void)

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      handleChange={onChange}
    >
      {({ isSubmitting }) => (
        <FormikForm
          className="flex flex-col space-y-4"
          data-testid={`form-${testId}`}
          name="testId"
        >
          {title && <h3 className="text-3xl font-bold p-2e">{title}</h3>}
          {intro && <div>{intro}</div>}
          {children}
          {(errorMessage || errorMessages) && (
            <div className="relative flex-grow">
              <div
                className="italic text-right text-red-500 text-s"
                role="alert"
              >
                {errorMessage ? (
                  <p data-testid="form-error">{errorMessage}</p>
                ) : (
                  errorMessages?.map((message, index) => (
                    <p key={`error-${index}`} data-testid="form-error">
                      {message}
                    </p>
                  ))
                )}
              </div>
            </div>
          )}
          <div className={buttonContainer}>
            <div className="relative flex space-x-4">
              {isSubmitting ? (
                <Loader size={"button"} />
              ) : (
                <>
                  {onCancel && (
                    <Button
                      type="button"
                      onClick={onCancel}
                      variant="cancel"
                      id="cancelBtn"
                      disabled={isSubmitting}
                    >
                      {"         Cancel           " || t("form.cancel")}
                    </Button>
                  )}
                  <Button
                    variant="secondary"
                    type="submit"
                    disabled={isSubmitting || open}
                  >
                    {" "}
                    {buttonText || t("form.submit")}
                  </Button>
                </>
              )}
            </div>
          </div>
        </FormikForm>
      )}
    </Formik>
  );
};
