import { Button, Divider } from "@mui/material";
import { MSGIcon, PhoneIcon } from "../Icons/icons";
import { CustomerDetails } from "../pages/CustomerOverviewPage/CustomerDetails";
import { useTranslation } from "react-i18next";
import { Dispatch, SetStateAction } from "react";

type ShowCustomerDetailsScreenProps = {
  customerDetailsResponse: CustomerDetails;
  setShowSearchScreen: Dispatch<SetStateAction<boolean>>;
  setShowMeters: Dispatch<SetStateAction<boolean>>;
  setShowChangePhoneNumberScreen: Dispatch<SetStateAction<boolean>>;
};

const ShowCustomerDetailsScreen = ({
  customerDetailsResponse,
  setShowSearchScreen,
  setShowMeters,
  setShowChangePhoneNumberScreen,
}: ShowCustomerDetailsScreenProps) => {
  const { t } = useTranslation();
  return (
    <div className="bg-[#FFFFFF] rounded-xl shadow-2xl mt-11 p-5">
      <span className="text-[24px] text-[#3F556F] font-semibold mt-7">
        {customerDetailsResponse.name}
      </span>
      <div className=" gap-x-5 text-[18px] flex flex-row text-[#3F556F] mt-5">
        <div className="flex flex-row justify-center items-center gap-x-2">
          <MSGIcon />
          <span> {customerDetailsResponse.email}</span>
        </div>
        <div className="flex flex-row justify-center items-center  gap-x-2">
          <PhoneIcon />
          <span>
            {t("changePhoneNumber.country_code")}{" "}
            {customerDetailsResponse.phoneNumber.slice(2, 10)}
          </span>
        </div>
      </div>
      <Divider sx={{ marginTop: "14px" }} />
      <div className="mt-5 flex">
        <table>
          <thead>
            <tr>
              <td className="p-4">
                <span className="text-[14px] text-[#3F556F]">
                  {t("changePhoneNumber.address")}
                </span>
              </td>
              <td className="p-4">
                <span className="text-[14px] text-[#3F556F]">
                  {t("changePhoneNumber.metzum_id")}
                </span>
              </td>
              <td className="p-4">
                <span className="text-[14px] text-[#3F556F]">
                  {t("changePhoneNumber.meter_id")}
                </span>
              </td>
            </tr>
          </thead>
          <tbody>
            {customerDetailsResponse &&
              customerDetailsResponse.meterDetails.map((meter, index) => {
                return (
                  <tr key={meter.metzumId + index} className="border-b  ">
                    <td className="p-4 border-r">
                      <span className="text-[14px] text-[#3F556F]">
                        {meter.address}
                      </span>
                    </td>
                    <td className="p-4 border-r">
                      <span className="text-[14px] text-[#3F556F]">
                        {meter.metzumId}
                      </span>
                    </td>
                    <td className="p-4">
                      <span className="text-[14px] text-[#3F556F]">
                        {meter.meterId}
                      </span>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      <div className="flex items-cente justify-center">
        <Button
          variant="secondary"
          color="primary"
          sx={{
            paddingY: "12px",
            paddingX: "71px",
            marginTop: 2,
          }}
          onClick={() => {
            setShowSearchScreen(false);
            setShowMeters(false);
            setShowChangePhoneNumberScreen(true);
          }}
        >
          <span className="font-semibold text-[17px]">
            {t("changePhoneNumber.select")}
          </span>
        </Button>
      </div>
    </div>
  );
};

export default ShowCustomerDetailsScreen;
