import * as Yup from "yup";

import { CredentialsForOTPForm } from "../../App/AuthType";
import { ForgetPasswordContext } from "./LoginPage";
import { Form } from "../../common/Forms/Form";
import { Input } from "../../common/Forms/Input";
import { backend } from "../../config/react-app-config";
import { useContext } from "react";
import { useLoader } from "../../components/GlobelLoader";
import { useSnackBar } from "../../components/SnackBarContext";
import { useTranslation } from "react-i18next";

export const OTPForm: React.FC = () => {
  const { t } = useTranslation();
  const { state, dispatch } = useContext(ForgetPasswordContext);
  const { showSnackBar } = useSnackBar();
  const { showLoader } = useLoader();
  const OTPSchema = Yup.object().shape({
    password: Yup.string().required(),
  });
  const onSubmit = (data: CredentialsForOTPForm): void => {
    const params = {
      email: state.forgetEmail,
      otPassword: data.password,
    };
    showLoader(true)
    backend
      .post("/api/v1/forgetPassword/verify-email-otp", params, {
        headers: {
          "Content-type": "application/json",
        },
      })
      .then((result) => {
        if (result.data.isVerified) {
          showSnackBar(result.data.message, "success");
          setTimeout(() => {
            dispatch({
              type: "Increment",
              payload: {
                ...state,
                activeIndex: 0,
                header: t("confirm_password.header"),
                otpToken: result.data.token,
                otpPassword: data.password,
              },
            });
          }, 1000);
        } else {
          showSnackBar(result.data.message, "error");
        }
        showLoader(false)
      });
  };
  const initialValues: CredentialsForOTPForm = {
    password: "",
  };
  return (
    <>
      <Form
        onSubmit={onSubmit}
        buttonText={t("otp_form.submit")}
        initialValues={initialValues}
        validationSchema={OTPSchema}
        buttonContainer={"flex ml-auto space-x-4 relative"}
      >
        <Input
          name="password"
          label={t("otp_form.forgot-password-to-your-account")}
          type="password"
        />
      </Form>
    </>
  );
};
