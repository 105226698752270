import "./OldDashboard.css";

import Box from "@mui/material/Box";
import { BsFillPersonPlusFill } from "react-icons/bs";
import FilterBar from "../OldKunde/FilterBar";
import { Stack } from "@mui/system";
import Typography from "@mui/material/Typography";
import UserTable from "./UserTable";

const OldDashboard = () => {
  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        bgcolor: "#F2F7FD",
        p: 3,
        pt: 2.46,
      }}
    >
      <Stack direction={"row"} spacing={2}>
        <BsFillPersonPlusFill color="#43AD8C" size={34} />
        <Typography
          variant="listText"
          fontFamily={"Inter"}
          fontSize={"2.125rem"}
          fontWeight={500}
          color={"#2B415A"}
          lineHeight={1}
        >
          Submission
        </Typography>
      </Stack>
      <FilterBar />
      <div className="pt-5">
        <UserTable />
      </div>
    </Box>
  );
};
export default OldDashboard;
