import * as React from "react";

import { Divider, Skeleton } from "@mui/material";
import { useEffect, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import DetailsStatus from "./DetailsStatus";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { DialogContext } from "../../common/Layout/OldKundundeLayout";
import DialogTitle from "@mui/material/DialogTitle";
import EditIcon from "../../Icons/EditIcon";
import EmailIcon from "@mui/icons-material/Email";
import IconButton from "@mui/material/IconButton";
import MailIcon from "../../Icons/MailIcon";
import PhoneIcon from "@mui/icons-material/Phone";
import { SelectedDialog } from "./Types";
import { Stack } from "@mui/system";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import { getUserDetailsById } from "./Api";
import { styled } from "@mui/material/styles";

const UserDetails = () => {
  const { dialogState, dialogDispatch } = React.useContext(DialogContext);
  const [show, setShow] = React.useState(true);

  const [userResponse, setUserResponse] = useState({
    ids: null,
    mobilenumber: "",
    email: "",
    fornavn: "",
    etternavn: "",
    personnummer: "",
    bolig: "",
    postnummer: "",
    sted: "",
    status: "",
    updated_at: "",
    created_at: "",
    wash_status: "",
    onboard_status: "",
    fhouisemovetype: "",
    from_api: "",
    velgdato: "",
    order_by_status: 1,
    m_lernummer: "",
    pay_extra: "",
    user_status: "",
    bad_credit_reasons: null,
  });
  useEffect(() => {
    if (dialogState.UserId) {
      setShow(true);
      getUserDetailsById(dialogState.UserId)
        .then((result) => {
          setUserResponse({
            ...result.data,
            updated_at: dayjs(result.data.updated_at).format(
              "DD MMM YYYY HH:mm"
            ),
            created_at: dayjs(result.data.created_at).format(
              "DD MMM YYYY HH:mm"
            ),
            velgdato: result.data.velgdato
              ? dayjs(result.data.velgdato, "MM/DD/YYYY").format("DD MMM YYYY")
              : "",
            user_status:
              result.data.status === "download" ? "New" : result.data.status,
            bad_credit_reasons: result.data.bad_credit_reasons,
          });
          setShow(false);
        })
        .catch((error) => {
          setShow(false);
          setUserResponse({
            ids: null,
            mobilenumber: "",
            email: "",
            fornavn: "",
            etternavn: "",
            personnummer: "",
            bolig: "",
            postnummer: "",
            sted: "",
            status: "",
            updated_at: "",
            created_at: "",
            wash_status: "",
            onboard_status: "",
            fhouisemovetype: "",
            from_api: "",
            velgdato: "",
            order_by_status: 1,
            m_lernummer: "",
            pay_extra: "",
            user_status: "",
            bad_credit_reasons: null,
          });
          console.log(error);
        });
    }
  }, [dialogState]);

  const handleClose = () => {
    // setOpen(false);
    dialogDispatch({ type: SelectedDialog.CLOSE, payload: {} });
  };
  return (
    <>
      {show ? (
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={dialogState.isOpen}
          maxWidth={"lg"}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          >
            <Skeleton className="w-80" />

            <Stack
              direction={"row"}
              alignItems={"center"}
              pt={1.3}
              pb={1}
              justifyContent={"space-between"}
            >
              <Typography fontSize={24} color={"#3F556F"} fontWeight={600}>
                <Skeleton className=" w-96 h-28" />
              </Typography>
            </Stack>
            <div className="flex flex-row pb-2">
              <Typography
                fontSize={18}
                fontWeight={400}
                color={"#3F556F"}
                className="pr-6 "
              >
                <div className="flex flex-row items-center ">
                  <span>
                    {" "}
                    <Skeleton className=" w-52 h-28" />
                  </span>
                </div>
              </Typography>
              <Typography fontSize={18} fontWeight={400} color={"#3F556F"}>
                <div className="flex flex-row items-center ">
                  <span>
                    {" "}
                    <Skeleton className=" w-52 h-28" />
                  </span>
                </div>
              </Typography>
            </div>
          </BootstrapDialogTitle>
          <Divider orientation="horizontal" flexItem />
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            px={1}
            py={1}
          >
            <div className="flex flex-col items-center justify-center flex-1">
              <Skeleton height={112} width={"80%"} />
            </div>
            <Divider orientation="vertical" flexItem />

            <div className="flex flex-col items-center justify-center flex-1">
              <Skeleton height={112} width={"80%"} />
            </div>
            <Divider orientation="vertical" flexItem />
            <div className="flex flex-col items-center justify-center flex-1">
              <Skeleton height={112} width={"80%"} />
            </div>
          </Stack>
          <DialogContent sx={{ maxWidth: 630, width: 623 }} dividers>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <div>
                <Skeleton className="w-36 " />
              </div>
              <div>
                <Skeleton className="w-36 " />
              </div>
              <div>
                <Skeleton className="w-36 " />
              </div>
            </Stack>
            <Divider orientation="vertical" flexItem />
            <Skeleton className="flex border rounded-lg h-96" height={280} />
          </DialogContent>
        </BootstrapDialog>
      ) : (
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={dialogState.isOpen}
          maxWidth={"lg"}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          >
            <Typography
              fontSize={14}
              variant="oldDashboardFont"
              fontWeight={400}
            >
              Last updated : {userResponse.updated_at}
            </Typography>
            <Stack
              direction={"row"}
              alignItems={"center"}
              pt={1.3}
              pb={1}
              justifyContent={"space-between"}
            >
              <Typography fontSize={24} color={"#3F556F"} fontWeight={600}>
                {`${userResponse.fornavn}  ${userResponse.etternavn}`}
              </Typography>
              <div className="flex flex-row justify-around">
                <MailIcon size="sm" className="mr-2" />
                <EditIcon size="sm" />
              </div>
            </Stack>
            <div className="flex flex-row pb-2">
              <Typography
                fontSize={18}
                fontWeight={400}
                color={"#3F556F"}
                className="pr-6 "
              >
                <EmailIcon sx={{ mr: 1.5, fontSize: 18 }} />{" "}
                {userResponse.email}
              </Typography>
              <Typography fontSize={18} fontWeight={400} color={"#3F556F"}>
                <PhoneIcon sx={{ fontSize: 18, mr: 0.8, fontWeight: 400 }} />{" "}
                {userResponse.mobilenumber}
              </Typography>
            </div>
          </BootstrapDialogTitle>
          <Divider orientation="horizontal" flexItem />
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            px={1}
            py={1}
          >
            <div className="flex flex-col items-center justify-center flex-1">
              <div>
                <Typography
                  fontFamily={"Inter"}
                  color={"#3F556F"}
                  fontSize={14}
                  fontWeight={400}
                  px={1}
                  textAlign={"left"}
                >
                  Personnummer
                </Typography>
                <Typography
                  fontFamily={"Inter"}
                  color={"#3F556F"}
                  fontSize={20}
                  pt={1}
                  fontWeight={500}
                  px={1}
                  sx={{ wordBreak: "break-all" }}
                >
                  {userResponse.personnummer}
                  <br />
                  <br />
                </Typography>
              </div>
            </div>
            <Divider orientation="vertical" flexItem />

            <div className="flex flex-col items-center justify-center flex-1">
              <div>
                <Typography
                  fontFamily={"Inter"}
                  color={"#3F556F"}
                  fontSize={14}
                  fontWeight={400}
                  px={1}
                  textAlign={"left"}
                >
                  Adresse
                </Typography>

                <Typography
                  fontFamily={"Inter"}
                  color={"#3F556F"}
                  fontSize={20}
                  pt={1}
                  px={1}
                  sx={{ wordBreak: "break-all" }}
                  fontWeight={500}
                  textAlign={"left"}
                >
                  {userResponse.bolig}
                  <br /> {userResponse.postnummer} {userResponse.sted}
                </Typography>
              </div>
            </div>
            <Divider orientation="vertical" flexItem />
            <div className="flex flex-col items-center justify-center flex-1">
              <div>
                <Typography
                  fontFamily={"Inter"}
                  color={"#3F556F"}
                  fontSize={14}
                  fontWeight={400}
                  px={1}
                  textAlign={"left"}
                >
                  Målepunkt-ID
                </Typography>
                <Typography
                  fontFamily={"Inter"}
                  color={"#3F556F"}
                  fontSize={20}
                  pt={1}
                  fontWeight={500}
                  px={1}
                  sx={{ wordBreak: "break-all" }}
                >
                  {userResponse.m_lernummer}
                  <br />
                  <br />
                </Typography>
              </div>
            </div>
          </Stack>
          <DialogContent sx={{ maxWidth: "100%", minWidth: 623 }} dividers>
            <UserDetailsStatus id={userResponse.order_by_status} />
            {userResponse.bad_credit_reasons && (
              <div className="grid grid-cols-2 py-2 pl-10 mt-2 border rounded-lg  border-[#FFAEAE]">
                <Typography
                  variant="oldDashboardFont"
                  fontWeight={600}
                  className="col-start-1"
                >
                  Credit Fail Reason
                </Typography>
                <Typography variant="oldDashboardFont" className="col-start-2">
                  {userResponse.bad_credit_reasons}
                </Typography>
              </div>
            )}
            <div className="grid grid-cols-2 py-2 pl-10 mt-2 border rounded-lg">
              <Typography
                variant="oldDashboardFont"
                fontWeight={600}
                className="col-start-1"
                pb={2}
                pt={1}
              >
                SR.NO
              </Typography>
              <Typography
                variant="oldDashboardFont"
                className="col-start-2"
                pb={2}
                pt={1}
              >
                {userResponse.ids}
              </Typography>
              <Typography
                variant="oldDashboardFont"
                fontWeight={600}
                className="col-start-1"
                pb={2}
              >
                API
              </Typography>
              <Typography
                variant="oldDashboardFont"
                className="col-start-2"
                pb={2}
              >
                {userResponse.from_api}
              </Typography>
              <Typography
                variant="oldDashboardFont"
                fontWeight={600}
                className="col-start-1"
                pb={2}
              >
                Bytter/Flytter
              </Typography>
              <Typography
                variant="oldDashboardFont"
                className="col-start-2"
                pb={2}
              >
                {userResponse.fhouisemovetype}
              </Typography>
              <Typography
                variant="oldDashboardFont"
                fontWeight={600}
                className="col-start-1"
                pb={2}
              >
                Pay extra
              </Typography>
              <Typography
                variant="oldDashboardFont"
                className="col-start-2"
                pb={2}
              >
                {userResponse.pay_extra}
              </Typography>
              <Typography
                variant="oldDashboardFont"
                fontWeight={600}
                className="col-start-1"
                pb={2}
              >
                Created
              </Typography>
              <Typography
                variant="oldDashboardFont"
                className="col-start-2"
                pb={2}
              >
                {userResponse.created_at}
              </Typography>
              <Typography
                variant="oldDashboardFont"
                fontWeight={600}
                className="col-start-1"
              >
                Updated
              </Typography>
              <Typography
                className="col-start-2"
                variant="oldDashboardFont"
                pb={2}
              >
                {userResponse.updated_at}
              </Typography>
              <Typography
                variant="oldDashboardFont"
                fontWeight={600}
                className="col-start-1"
              >
                Requested start date
              </Typography>
              <Typography className="col-start-2" variant="oldDashboardFont">
                {userResponse.velgdato}
              </Typography>
            </div>
          </DialogContent>
        </BootstrapDialog>
      )}
    </>
  );
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, pl: 2, pb: 0, pr: 1 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 0,
            p: 1,
            top: 2,

            color: (theme) => "#3F556F",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
type UserDetailsStatusProps = {
  id: number;
};

const UserDetailsStatus: React.FC<UserDetailsStatusProps> = ({ id }) => {
  // all 1's means not started expect userStatus
  // all 2's means success
  // all 3's means fail
  // all 4 means other status
  const [labels, setLabels] = useState({
    userStatus: 0,
    washStatus: 1,
    onboardingStatus: 1,
  });

  useEffect(() => {
    if (id === 1) {
      setLabels((label) => {
        return {
          ...label,
          userStatus: 0,
        };
      });
    }
    if (id === 2) {
      setLabels((label) => {
        return {
          ...label,
          userStatus: 6,
          washStatus: 5,
        };
      });
    }
    if (id === 3) {
      setLabels((label) => {
        return {
          ...label,
          userStatus: 6,
          washStatus: 3,
        };
      });
    }
    if (id === 6) {
      setLabels((label) => {
        return {
          ...label,
          userStatus: 6,
          washStatus: 7,
        };
      });
    }
    if (id === 4) {
      setLabels((label) => {
        return {
          ...label,
          userStatus: 6,
          washStatus: 5,
          onboardingStatus: 5,
        };
      });
    }
    if (id === 5) {
      setLabels((label) => {
        return {
          ...label,
          userStatus: 6,
          washStatus: 5,
          onboardingStatus: 4,
        };
      });
    }
  }, []);

  return (
    <Stack
      direction={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <DetailsStatus currentStatus={labels.userStatus} title="New">
        New
      </DetailsStatus>
      <DetailsStatus currentStatus={labels.washStatus} title="Washing">
        Washing
      </DetailsStatus>
      <DetailsStatus currentStatus={labels.onboardingStatus}>
        On-boarding
      </DetailsStatus>
      {/* <DetailsStatus currentStatus={userResponse.status}>
      {userResponse.user_status}
    </DetailsStatus>
    <DetailsStatus
      currentStatus={userResponse.wash_status}
      title={`Washing ${
        userResponse.wash_status
          ? userResponse.wash_status.toLowerCase()
          : ""
      }`}
      description={
        "An error has occured. Please check if the given meter is correct."
      }
    >
      {`Washing ${userResponse.wash_status.toLowerCase()}`}
    </DetailsStatus>
    <DetailsStatus
      currentStatus={userResponse.onboard_status}
      title={`On-boarding ${
        userResponse.onboard_status
          ? userResponse.onboard_status.toLowerCase()
          : ""
      }`}
      description={"Please refer to the logs for more information"}
    >
      {`On-boarding ${
        userResponse.onboard_status
          ? userResponse.onboard_status.toLowerCase()
          : ""
      }`}
    </DetailsStatus> */}
    </Stack>
  );
};

export default UserDetails;
