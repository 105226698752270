import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  ids: {
    asc: false,
    desc: false,
  },
  fullname: {
    asc: false,
    desc: false,
  },
  status: {
    asc: false,
    desc: false,
  },
  email: {
    asc: false,
    desc: false,
  },
  mobilenumber: {
    asc: false,
    desc: false,
  },
  personnummer: {
    asc: false,
    desc: false,
  },
  address: {
    asc: false,
    desc: false,
  },
  updated_at: {
    asc: false,
    desc: false,
  },
};
const sortSlice = createSlice({
  name: "sortSlice",
  initialState: {
    ...initialState,
    ids: {
      asc: false,
      desc: true,
    },
  },
  reducers: {
    changeSort(state, action) {
      return action.payload;
    },
  },
});
export const sortActions = sortSlice.actions;
export default sortSlice.reducer;
