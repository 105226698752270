import CloseIcon from '@mui/icons-material/Close';
import { CommonProps } from '../../pages/OldKunde/Types';
import LabelContainer from "./Container";
import { Typography } from "@mui/material";

const FailStatus = ({ children }:CommonProps) => {
  return (
    <LabelContainer status={"bg-fail"}>
      <CloseIcon color={"error"} sx={{ mr: 0.5, fontSize: 14 }} />
      <Typography fontSize={14} variant="oldDashboardFont" color={"#D43E3E"}>
        {" "}
        {children}
      </Typography>
    </LabelContainer>
  );
};
export default FailStatus;
