import { CommonProps } from "../../pages/OldKunde/Types";
import LabelContainer from "./Container";
import { Typography } from "@mui/material";

const NewStatus = ({ children }: CommonProps) => {
  return (
    <LabelContainer status={"bg-statusNew"}>
      <Typography fontSize={14} variant="oldDashboardFont" color={"#fff"}>
        {children}
      </Typography>
    </LabelContainer>
  );
};

export default NewStatus;
