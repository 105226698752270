import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router";

import { BsFillPersonPlusFill } from "react-icons/bs";
import { OLD_KUNDE_DASHBOARD } from "../../App/AuthType";
import { sideBardMenu } from "./Types";
import styled from "@emotion/styled";
import { EditPhoneNumberIcon } from "../../Icons/icons";

type IconsSelectProps = {
  index: number;
  isSelected?: boolean;
};
const ListComponent = styled(List)({
  // selected and (selected + hover) states
  "&& .Mui-selected, && .Mui-selected:hover": {
    backgroundColor: "red",
    "&, & .MuiListItemIcon-root": {
      color: "pink",
    },
  },
  // hover states
  "& .MuiListItemButton-root:hover": {
    backgroundColor: "#43AD8C",
    "&, & .MuiListItemIcon-root": {},
  },
});
export const SideMenu = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const routes = [
    {
      pageUrl: OLD_KUNDE_DASHBOARD + "/submission",
      title: sideBardMenu.Submission,
    },
    {
      pageUrl: OLD_KUNDE_DASHBOARD + "/changeuserphonenumber",
      title: sideBardMenu.ChangeUserPhoneNumber,
    },
  ];

  const redirectToPage = (pageUrl: string) => {
    navigate(pageUrl);
  };

  return (
    <div>
      <ListComponent>
        {routes.map((text, index) => (
          <ListItem
            onClick={() => redirectToPage(text.pageUrl)}
            key={text.pageUrl}
            sx={{ paddingInline: 2.5 }}
            disablePadding
          >
            <ListItemButton
              sx={{
                borderRadius: 2,
                marginTop: 2,
                bgcolor:
                  location.pathname === text.pageUrl ? "#43AD8C" : "#fff",
              }}
            >
              <ListItemIcon
                color={location.pathname === text.pageUrl ? "#43AD8C" : "#fff"}
              >
                {
                  <IconsSelect
                    isSelected={location.pathname === text.pageUrl}
                    index={index}
                  />
                }
              </ListItemIcon>
              <ListItemText
                sx={{
                  color:
                    location.pathname === text.pageUrl ? "#fff" : "#74767D",
                }}
                primary={text.title}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </ListComponent>
    </div>
  );
};
const IconsSelect = ({ index, isSelected = false }: IconsSelectProps) => {
  let icon;
  switch (index) {
    case 0:
      icon = (
        <BsFillPersonPlusFill
          size={23}
          style={{ color: isSelected ? "#FFFFFF" : "#43AD8C" }}
        />
      );
      break;
    case 1:
      icon = <EditPhoneNumberIcon color={isSelected ? "white" : "#43AD8C"} />;
      break;
    default:
      icon = null;
      break;
  }
  return icon;
};
