import React, { createContext, useContext } from "react";

import { CircularProgress } from "@mui/material";
import { Stack } from "@mui/system";

type LoaderContextActions = {
  showLoader: (state: boolean) => void;
  open: boolean;
};

const LoaderBarContext = createContext({} as LoaderContextActions);

interface LoaderContextProviderProps {
  children: React.ReactNode;
}

const LoaderProvider: React.FC<LoaderContextProviderProps> = ({ children }) => {
  const [open, setOpen] = React.useState<boolean>(false);
  // const [message, setMessage] = React.useState<string>("");
  // const [typeColor, setTypeColor] = React.useState<AlertColor>("info");

  const showLoader = (state: boolean) => {
    // setMessage(text);
    // setTypeColor(color);
    if (!state) {
      setTimeout(() => {
        setOpen(state);
      }, 1000);
    } else {
      setOpen(state);
    }
  };

  return (
    <LoaderBarContext.Provider value={{ showLoader, open }}>
      {open && (
        <Stack justifyContent={"center"} alignItems={"center"}>
          {<CircularProgress color="inherit" />}
        </Stack>
      )}
      {children}
    </LoaderBarContext.Provider>
  );
};

const useLoader = (): LoaderContextActions => {
  const context = useContext(LoaderBarContext);

  if (!context) {
    throw new Error("useLoader must be used within an LoaderProvider");
  }

  return context;
};

export { LoaderProvider, useLoader };
