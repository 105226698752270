import React, { useContext } from "react";

import { ConfirmPasswordForm } from "./ConfirmPasswordForm";
import { ForgetPasswordContext } from "./LoginPage";
import { ForgetPasswordForm } from "./ForgetPasswordForm";
import { LoginForm } from "./LoginForm";
import { OTPForm } from "./OTPForm";

const Step = () => {
  const { state } = useContext(ForgetPasswordContext);
  let stepContent;
  switch (state.activeIndex) {
    case 0:
      stepContent = <LoginForm />;
      break;
    case 1:
      stepContent = <ForgetPasswordForm />;
      break;
    case 2:
      stepContent = <OTPForm />;
      break;
    case 3:
      stepContent = <ConfirmPasswordForm />;
      break;
    default:
      stepContent = null;
      break;
  }

  return stepContent;
};

export default Step;
