import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import { CloseIcon } from "../Icons/icons";
import { useState } from "react";
import { updateMeterId } from "../pages/CustomerOverviewPage/changePhoneNumber-api";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";

type ChangeMeterModalProps = {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  rowId: string;
};

function ChangeMeterModal({
  setShowModal,
  showModal,
  rowId,
}: ChangeMeterModalProps) {
  const [newMeterId, setNewMeterId] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const handleUpdateMeterId = () => {
    setErrMsg("");
    const isValidPhoneNumber = /^[0-9]{18}$/.test(newMeterId);
    if (isValidPhoneNumber) {
      updateMeterId(newMeterId, rowId)
        .then((res) => {
          setShowModal(false);
          setErrMsg("");
          setNewMeterId("");
          queryClient.invalidateQueries(["FilterCounts"]);
          queryClient.invalidateQueries(["UsersTable"]);
        })
        .catch((error) => {
          console.error("Error while updating meter id:", error);
          setErrMsg(error.response.data);
        });
    } else {
      setErrMsg("Please enter a valid 18-digit meter id.");
    }
  };
  return (
    <Dialog
      fullWidth
      open={showModal}
      onClose={() => {
        setShowModal(false);
        setNewMeterId("");
      }}
    >
      <DialogTitle className="text-6 flex flex-row justify-between items-center  text-[#3F556F]">
        <span className="flex">{t("changeMeterIdModal.modalTitle")}</span>
        <button
          onClick={() => {
            setShowModal(false);
            setNewMeterId("");
          }}
        >
          <CloseIcon />
        </button>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <span className="text-[14px] text-[#3F556F]">
            {t("changeMeterIdModal.meterId")}
          </span>
        </DialogContentText>
        <TextField
          value={newMeterId}
          autoFocus
          margin="dense"
          id="name"
          type="number"
          fullWidth
          onChange={(event) => {
            const enteredMeterId = event.target.value;
            const numericMeterId = enteredMeterId
              .replace(/\D/g, "")
              .slice(0, 18);
            setNewMeterId(numericMeterId);
            setErrMsg("");
          }}
        />
        {errMsg !== "" && <span className="mt-1 text-[red]">{errMsg}</span>}
      </DialogContent>
      <DialogActions>
        <div className="flex flex-row justify-center item-center gap-x-2 w-full px-4 mb-3">
          <Button
            className="w-full"
            variant="outlined"
            onClick={() => {
              setNewMeterId("");
              setShowModal(false);
            }}
            color="error"
            sx={{ borderRadius: 0 }}
          >
            <span className="text-[#D43E3E] text-[15px]">
              {t("changeMeterIdModal.cancel")}
            </span>
          </Button>
          <Button
            color="info"
            className="w-full text-[#D43E3E] text-[15px]"
            onClick={handleUpdateMeterId}
            variant="contained"
            sx={{ background: "#104EEE", borderRadius: 0 }}
          >
            <span className=" text-[15px]">
              {t("changeMeterIdModal.update")}
            </span>
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default ChangeMeterModal;
