import * as Yup from "yup";

import { CredentialsForConfirmPassword } from "../../App/AuthType";
import { ForgetPasswordContext } from "./LoginPage";
import { Form } from "../../common/Forms/Form";
import { Input } from "../../common/Forms/Input";
import { backend } from "../../config/react-app-config";
import { useContext } from "react";
import { useLoader } from "../../components/GlobelLoader";
import { useSnackBar } from "../../components/SnackBarContext";
import { useTranslation } from "react-i18next";

export const ConfirmPasswordForm: React.FC = () => {
  const { t } = useTranslation();
  const { state, dispatch } = useContext(ForgetPasswordContext);
  const { showSnackBar } = useSnackBar();
  const { showLoader } = useLoader();
  const OTPSchema = Yup.object().shape({
    password: Yup.string().required(),
    confirm_password: Yup.string()
      .required()
      .when("password", {
        is: (val: string) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Both passwords must match"
        ),
      }),
  });
  const onSubmit = (data: CredentialsForConfirmPassword) => {
    showLoader(true);
    const params = {
      oldPassword: state.otpPassword,
      newPassword: data.password,
    };
    backend
      .post("/api/v1/auth/reset-password", params, {
        headers: {
          Authorization: `Bearer ${state.otpToken}`,
        },
      })
      .then((result) => {
        if (result.data.isResetPasswordDone) {
          showSnackBar(result.data.message, "success");
          setTimeout(() => {
            dispatch({
              type: "Reset",
              payload: {
                ...state,
                activeIndex: 0,
                header: t("login.sign-in-to-your-account"),
              },
            });
          }, 1000);
        } else {
          showSnackBar(result.data.message, "error");
        }
        showLoader(false);
      })
      .catch((error) => {
        showSnackBar("Something goes wrong!!", "error");
        showLoader(false);
      });
  };
  const initialValues: CredentialsForConfirmPassword = {
    password: "",
    confirm_password: "",
  };
  return (
    <>
      <Form
        onSubmit={onSubmit}
        buttonText={t("confirm_password.submit")}
        initialValues={initialValues}
        validationSchema={OTPSchema}
        buttonContainer={"flex ml-auto space-x-4 relative"}
      >
        <Input
          name="password"
          label={t("confirm_password.password")}
          type="password"
        />
        <Input
          name="confirm_password"
          label={t("confirm_password.confirm")}
          type="password"
        />
      </Form>
    </>
  );
};
