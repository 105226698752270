import { CommonProps } from "../../pages/OldKunde/Types";
import LabelContainer from "./Container";
import { Typography } from "@mui/material";

const NotStarted = ({ children }: CommonProps) => {
  return (
    <LabelContainer status={"bg-notStarted"}>
      <Typography fontSize={14} variant="oldDashboardFont" color={"#CACACA"}>
        {children}
      </Typography>
    </LabelContainer>
  );
};
export default NotStarted;
