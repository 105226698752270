import { createContext, useEffect, useReducer, useState } from "react";

import { LoaderProvider } from "../../components/GlobelLoader";
import { SnackBarProvider } from "../../components/SnackBarContext";
import Step from "./Step";
import { useLogin } from "../../App/auth";
import { useTranslation } from "react-i18next";

export type ForgetFormData = {
  activeIndex: number;
  header: string;
  otpToken: string;
  forgetEmail: string;
  otpPassword: string;
};
export type ForgetFormAction = {
  type: String;
  payload: ForgetFormData;
};
const initialState: ForgetFormData = {
  activeIndex: 0,
  header: "",
  otpToken: "",
  forgetEmail: "",
  otpPassword: "",
};
export const ForgetPasswordContext = createContext<{
  state: ForgetFormData;
  dispatch: React.Dispatch<ForgetFormAction>;
}>({
  state: initialState,
  dispatch: () => undefined,
});
const reducer = (state: ForgetFormData, actions: ForgetFormAction) => {
  switch (actions.type) {
    case "Increment":
      return {
        ...actions.payload,
        activeIndex: state.activeIndex + 1,
        header: actions.payload.header,
      };
    case "Reset":
      return {
        ...actions.payload,
        activeIndex: 0,
        header: actions.payload.header,
      };
    default:
      return state;
  }
};
export const LoginPage = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [errorMessage, setErrorMessage] = useState<string>();
  const { t } = useTranslation();
  const { error } = useLogin();

  useEffect(() => {
    console.log(error)
    if (
      error &&
      error.response &&
      error.response.status &&
      [400, 401, 402].includes(error.response.status)
    ) {
      setErrorMessage(t("error.invalid-email-or-password"));
    } else {
      setErrorMessage(undefined);
    }
  }, [error, t]);

  return (
    <div className="h-screen bg-gray-50">
      <div className="flex flex-col justify-center py-12 min-h-3/4 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="mt-6 text-3xl font-extrabold text-center text-gray-900">
            {state.header || t("login.sign-in-to-your-account")}
          </h2>
        </div>
        <div className="relative mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="px-4 py-8 bg-white shadow sm:rounded-lg sm:px-10">
            <SnackBarProvider>
              <LoaderProvider>
                <ForgetPasswordContext.Provider
                  value={{
                    state,
                    dispatch,
                  }}
                >
                  <Step />
                </ForgetPasswordContext.Provider>
              </LoaderProvider>
            </SnackBarProvider>
            {errorMessage && (
              <div
                className="mt-6 italic text-right text-red-500 text-s"
                role="alert"
                data-testid="login-error"
              >
                <p>{t(errorMessage)}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
