import * as Yup from "yup";

import { CredentialsForForgetPassword } from "../../App/AuthType";
import { ForgetPasswordContext } from "./LoginPage";
import { Form } from "../../common/Forms/Form";
import { GridCloseIcon } from "@mui/x-data-grid";
import { Input } from "../../common/Forms/Input";
import { backend } from "../../config/react-app-config";
import { useContext } from "react";
import { useLoader } from "../../components/GlobelLoader";
import { useSnackBar } from "../../components/SnackBarContext";
import { useTranslation } from "react-i18next";

export const ForgetPasswordForm: React.FC = () => {
  const { t } = useTranslation();
  const { state, dispatch } = useContext(ForgetPasswordContext);
  const { showLoader } = useLoader();
  const OTPSchema = Yup.object().shape({
    email: Yup.string().required(),
  });
  const { showSnackBar } = useSnackBar();

  const onSubmit = (data: CredentialsForForgetPassword) => {
    const params = {
      email: data.email,
    };
    showLoader(true);
    backend
      .post("/api/v1/forgetPassword/sentOTPMail", params, {
        headers: {
          "Content-type": "application/json",
        },
      })
      .then((result) => {
    
        if (result.data.isOtpSent) {
          showSnackBar(result.data.message, "success");
          setTimeout(() => {
            dispatch({
              type: "Increment",
              payload: {
                ...state,
                activeIndex: 0,
                header: t("otp_form.header"),
                forgetEmail: data.email,
              },
            });
          }, 1000);

          showLoader(false);
        } else {
          showSnackBar(result.data.message, "error");
          showLoader(false);
        }
      })
      .catch((error) => {
        showSnackBar("Something goes wrong!!", "error");
        showLoader(false);
      });
  };
  const initialValues: CredentialsForForgetPassword = {
    email: "",
  };
  return (
    <>
      <Form
        onSubmit={onSubmit}
        buttonText={t("forgot_password.submit-otp")}
        initialValues={initialValues}
        validationSchema={OTPSchema}
        buttonContainer={"flex ml-auto space-x-4 relative"}
      >
        <Input
          name="email"
          label={t("forgot_password.forgot-password-to-your-account")}
          type="email"
        />
      </Form>
    </>
  );
};
