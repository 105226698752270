import { createSlice } from "@reduxjs/toolkit";

type SearchState = {
  searchQuery: string;
};
const initialState: SearchState = {
  searchQuery: "",
};
const searchSlice = createSlice({
  name: "SearchSlice",
  initialState,
  reducers: {
    findQuery(state, action) {
      return action.payload;
    },
  },
});
export const searchAction = searchSlice.actions;
export default searchSlice.reducer;
