const LabelContainer = ({ children, status = "bg-success" }: any) => {
  return (
    <div
      className={"flex items-center justify-center flex-1 py-1 mr-2 " + status}
    >
      {children}
    </div>
  );
};
export default LabelContainer;
