import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import { HomeIcon } from "@heroicons/react/solid";
import Logout from "@mui/icons-material/Logout";
import Toolbar from "@mui/material/Toolbar";
import { logout } from "../../App/auth";
import styles from "./Navbar.module.css";
import { useNavigate } from "react-router";

export const ResponsiveAppBar = () => {
  const logoutPage = () => {
    logout();
  };

  const navigate = useNavigate();

  const navigateCustomerPage = () => {
    navigate("/");
  };
  return (
    <>
      <AppBar
        position="fixed"
        className="px-3"
        style={{ backgroundColor: "#fff" }}
      >
        <Toolbar disableGutters className="justify-between">
          <Button
            onClick={navigateCustomerPage}
            type="button"
            className="flex items-center "
          
          >
            <HomeIcon className="w-10 h-10 mr-2 text-emerald-400/[.8] "  />
            <label className={styles.textColor}>Home</label>
          </Button>
          <Button onClick={logoutPage} variant="secondary">
            <Logout sx={{ display: { xs: "none", md: "flex" }, mr: 1 }} />
            Logout
          </Button>
        </Toolbar>
      </AppBar>
    </>
  );
};
