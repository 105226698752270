import "react-phone-number-input/style.css";
import "./Modal.css";

import { Backdrop, Card } from "@mui/material";

export const Modal = ({ children ,closeModel}: any) => {
 
  return (
    <Backdrop sx={{ color: "#fff", zIndex: 1100 }} onClick={closeModel} open={true}>
      <Card
        variant="outlined"
        sx={{
          borderRadius: "20px",
          boxShadow: "rgb(0 0 0 / 35%) 0px 5px 15px",
        }}
        className="p-6"
      >
       {children} 
      
      </Card>
    </Backdrop>
  );
};

  
