import { Outlet } from "react-router";
import React from "react";
import { ResponsiveAppBar } from "../../pages/NavBar/Navbar";

export const Layout = () => {
  return (
    <>
      <ResponsiveAppBar />
      <main>
        <Outlet />
      </main>
    </>
  );
};
