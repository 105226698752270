import { QueryClient, QueryClientProvider } from "react-query";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import { BrowserRouter } from "react-router-dom";

// const queryConfig:QueryClientConfig = {
//   cacheTime: 600000, // 10 minutes
//   staleTime: 60000, // 1 minute
//   refetchOnWindowFocus: false,
// };

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 60000,
      cacheTime: 24 * 60 * 60 * 1000,
    },
  },
});
declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    secondary: true;
    cancel: true;
    link: true;
  }
}
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    listText: true;
    oldDashboardFont: true;
    showCount: true;
    selectedShowCount: true;
    labelForFilter: true;
  }
}
declare module "@mui/material/TableCell" {
  interface TableCellPropsVariantOverrides {
    header: true;
    cell: true;
  }
}
declare module "@mui/material/Chip" {
  interface ChipPropsVariantOverrides {
    all: true;
    new: true;
    fail: true;
    success: true;
    warning:true;
  }
}
declare module "@mui/material/Pagination" {
  interface PaginationPropsVariantOverrides {
    customBtn: true;
  }
}

declare module "@mui/material/PaginationItem" {
  interface PaginationItemPropsVariantOverrides {
    customBtn: true;
  }
}

export const AppProviders: React.FC = ({ children }) => {
  const theme = createTheme({
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            // fontSize: "1rem",

            textTransform: "capitalize",
            fontFamily: "reader",
            fontSize: "1rem",
          },
        },
        variants: [
          {
            props: { variant: "secondary" },
            style: {
              borderRadius: "10px",
              backgroundColor: "#61D8AA",
              color: "#fff",
              "&:hover": {
                backgroundColor: "#61D8AA",
                // color: "#fff",
              },
            },
          },
          {
            props: { variant: "link" },
            style: {},
          },
          {
            props: { variant: "cancel" },
            style: {
              borderRadius: "10px",
              backgroundColor: "#808080",
              minWidth: "100px",
              color: "#fff",
              "&:hover": {
                backgroundColor: "#808080",
                // color: "#fff",
              },
            },
          },
          {
            props: { variant: "text" },
            style: {
              borderRadius: "10px",
            },
          },
        ],
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            "& $notchedOutline": {
              borderColor: "green",
            },
            "&:hover $notchedOutline": {
              borderColor: "red",
            },
            "&$focused $notchedOutline": {
              borderColor: "purple",
            },
          },
        },
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            minWidth: "32px",
          },
        },
      },
      MuiListItemText: {
        styleOverrides: {
          root: {
            fontFamily: "initial  ",
          },
        },
      },
      MuiTypography: {
        styleOverrides: {},
        variants: [
          {
            props: { variant: "oldDashboardFont" },
            style: {
              fontFamily: "Inter",
              color: "#3F556F",
            },
          },
          {
            props: { variant: "showCount" },
            style: {
              fontFamily: "Inter",
              fontWeight: 400,
              fontSize: 20,
            },
          },
          {
            props: { variant: "selectedShowCount" },
            style: {
              fontFamily: "Inter",
              fontWeight: 600,
              fontSize: 20,
            },
          },
          {
            props: { variant: "labelForFilter" },
            style: {
              fontFamily: "Inter",
              fontSize: "0.8rem",
              textTransform:"none"
            },
          },
        ],
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            padding: 8,
            fontSize: 14,
            fontFamily: "Inter",
            fontWeight: 400,
            // lineHeight: 1,
          },
        },
        variants: [
          {
            props: { variant: "header" },
            style: {
              color: "#3F556F",
              fontWeight: 600,
              fontSize: "0.875rem",
            },
          },
          {
            props: { variant: "cell" },
            style: {
              color: "#3F556F",
              fontWeight: 400,
            },
          },
        ],
      },
      MuiChip: {
        styleOverrides: {
          root: {
            fontFamily: "Inter",
            color: "#fff",
          },
        },
        variants: [
          {
            props: { variant: "new" },
            style: {
              backgroundColor: "#104EEE",
            },
          },
          {
            props: { variant: "fail" },
            style: {
              backgroundColor: "#EB6767",
            },
          },
          {
            props: { variant: "success" },
            style: {
              backgroundColor: "#3DBE42",
            },
          },
          {
            props: { variant: "warning" },
            style: {
              backgroundColor: "#D78C0C",
            },
          },
        ],
      },
      MuiToggleButton: {
        styleOverrides: {
          root: {
            "&.MuiToggleButtonGroup-grouped": {
              borderRadius: "12px !important",
            },
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.15)",
          },
        },
      },
      MuiTableSortLabel: {
        styleOverrides: {
          root: {
            "&:hover ": {
              color: "#3F556F",
            },
            "&:focus ": {
              color: "#3F556F",
            },
          },
        },
      },
      MuiPagination: {
        styleOverrides: {
          root: {
            button: {
              color: "#3F556F",
            },
          },
        },
        variants: [
          {
            props: { variant: "customBtn" },
            style: {
              color: "black",
            },
          },
        ],
      },
    },
    palette: {
      primary: {
        main: "#43AD8C",
        contrastText: "#fff",
      },
      success: {
        main: "#54BC4E",
      },
      error: {
        main: "#D43E3E",
      },
    },
  });
  return (
    <QueryClientProvider client={queryClient} contextSharing={true}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>{children}</BrowserRouter>
      </ThemeProvider>
    </QueryClientProvider>
  );
};
