import { Navigate, Route, Routes } from "react-router-dom";
import { OLD_KUNDE_DASHBOARD, RoleNames } from "./AuthType";
import {
  faCaretDown,
  faCaretUp,
  faEnvelope,
  faGear,
  faPenSquare,
  faPenToSquare,
  faRightFromBracket,
} from "@fortawesome/free-solid-svg-icons";

import { DashboardScreen } from "../pages/Dashboard/DashboardScreen";
import ImpersonationPage from "../pages/Impersonation/ImpersonationPage";
import { Layout } from "../common/Layout/Layout";
import { LoginPage } from "../pages/LoginPage/LoginPage";
import MissingScreen from "../common/Layout/MissingPage";
import OldDashboard from "../pages/OldKunde/OldDashBoard";
import OldKundeLayout from "../common/Layout/OldKundundeLayout";
import { RequireAuth } from "./RequireAuth";
import UnauthorizedScreen from "../common/Layout/UnauthorizedScreen";
import { library } from "@fortawesome/fontawesome-svg-core";
import ChangeUserPhonePage from "../components/ChangeUserPhonePage";

library.add(
  faRightFromBracket,
  faPenSquare,
  faGear,
  faPenToSquare,
  faCaretUp,
  faCaretUp,
  faCaretDown,
  faEnvelope
);

export const App = () => {
  return (
    <Routes>
      {/* Public routes */}
      <Route path="login" element={<LoginPage />}></Route>
      <Route path="unauthorized" element={<UnauthorizedScreen />}></Route>
      {/* Protected routes */}
      <Route
        element={
          <RequireAuth
            allowRoles={[
              RoleNames.admin,
              RoleNames.superAdmin,
              RoleNames.finance,
            ]}
          />
        }
      >
        <Route path="/" element={<Layout />}>
          <Route path="/" element={<DashboardScreen />}></Route>
          <Route path="impersonation" element={<ImpersonationPage />}></Route>
        </Route>
        <Route path={OLD_KUNDE_DASHBOARD} element={<OldKundeLayout />}>
          <Route
            path={OLD_KUNDE_DASHBOARD}
            element={<Navigate to={"submission"} />}
          ></Route>
          <Route path={"submission"} element={<OldDashboard />}></Route>
          <Route
            path={OLD_KUNDE_DASHBOARD + "/changeuserphonenumber"}
            element={<ChangeUserPhonePage />}
          />
        </Route>

        {/* Catch all */}
        <Route path="*" element={<MissingScreen />}></Route>
      </Route>
    </Routes>
  );
};
