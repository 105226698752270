const EditPhoneNumberIcon = ({ color = "white" }) => {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 14.5C15.8 14.5 14.5 14.3 13.4 13.9H13.1C12.8 13.9 12.6 14 12.4 14.2L10.2 16.4C7.4 14.9 5 12.6 3.6 9.8L5.8 7.6C6.1 7.3 6.2 6.9 6 6.6C5.7 5.5 5.5 4.2 5.5 3C5.5 2.5 5 2 4.5 2H1C0.5 2 0 2.5 0 3C0 12.4 7.6 20 17 20C17.5 20 18 19.5 18 19V15.5C18 15 17.5 14.5 17 14.5ZM2 4H3.5C3.6 4.9 3.8 5.8 4 6.6L2.8 7.8C2.4 6.6 2.1 5.3 2 4ZM16 18C14.7 17.9 13.4 17.6 12.2 17.2L13.4 16C14.2 16.2 15.1 16.4 16 16.4V18ZM14 2V0.5L11.75 2.75L14 5V3.5C14.663 3.5 15.2989 3.76339 15.7678 4.23223C16.2366 4.70107 16.5 5.33696 16.5 6C16.5 6.4 16.41 6.78 16.24 7.12L17.33 8.21C17.75 7.58 18 6.82 18 6C18 3.79 16.21 2 14 2ZM14 8.5C13.337 8.5 12.7011 8.23661 12.2322 7.76777C11.7634 7.29893 11.5 6.66304 11.5 6C11.5 5.6 11.59 5.22 11.76 4.88L10.67 3.79C10.25 4.42 10 5.18 10 6C10 8.21 11.79 10 14 10V11.5L16.25 9.25L14 7V8.5Z"
        fill={color}
      />
    </svg>
  );
};

const EditPhoneNumberTitleIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 30 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.6667 22.5417C25.7667 22.5417 23.7083 22.225 21.9667 21.5917H21.4917C21.0167 21.5917 20.7 21.75 20.3833 22.0667L16.9 25.55C12.4667 23.175 8.66667 19.5333 6.45 15.1L9.93333 11.6167C10.4083 11.1417 10.5667 10.5083 10.25 10.0333C9.775 8.29167 9.45833 6.23333 9.45833 4.33333C9.45833 3.54167 8.66667 2.75 7.875 2.75H2.33333C1.54167 2.75 0.75 3.54167 0.75 4.33333C0.75 19.2167 12.7833 31.25 27.6667 31.25C28.4583 31.25 29.25 30.4583 29.25 29.6667V24.125C29.25 23.3333 28.4583 22.5417 27.6667 22.5417ZM3.91667 5.91667H6.29167C6.45 7.34167 6.76667 8.76667 7.08333 10.0333L5.18333 11.9333C4.55 10.0333 4.075 7.975 3.91667 5.91667ZM26.0833 28.0833C24.025 27.925 21.9667 27.45 20.0667 26.8167L21.9667 24.9167C23.2333 25.2333 24.6583 25.55 26.0833 25.55V28.0833ZM22.9167 2.75V0.375L19.3542 3.9375L22.9167 7.5V5.125C23.9665 5.125 24.9733 5.54204 25.7156 6.28437C26.458 7.0267 26.875 8.03352 26.875 9.08333C26.875 9.71667 26.7325 10.3183 26.4633 10.8567L28.1892 12.5825C28.8542 11.585 29.25 10.3817 29.25 9.08333C29.25 5.58417 26.4158 2.75 22.9167 2.75ZM22.9167 13.0417C21.8669 13.0417 20.86 12.6246 20.1177 11.8823C19.3754 11.14 18.9583 10.1331 18.9583 9.08333C18.9583 8.45 19.1008 7.84833 19.37 7.31L17.6442 5.58417C16.9792 6.58167 16.5833 7.785 16.5833 9.08333C16.5833 12.5825 19.4175 15.4167 22.9167 15.4167V17.7917L26.4792 14.2292L22.9167 10.6667V13.0417Z"
        fill="#43AD8C"
      />
    </svg>
  );
};

const MSGIcon = () => {
  return (
    <svg
      width="14"
      height="11"
      viewBox="0 0 14 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.25665 0.0878906C0.562874 0.0878906 0 0.689337 0 1.43065C0 1.85307 0.185879 2.2503 0.50266 2.50486L6.19947 7.07026C6.49792 7.30804 6.90633 7.30804 7.20479 7.07026L12.9016 2.50486C13.2184 2.2503 13.4043 1.85307 13.4043 1.43065C13.4043 0.689337 12.8414 0.0878906 12.1476 0.0878906H1.25665ZM0 3.22101V9.03965C0 10.0271 0.751371 10.83 1.67553 10.83H11.7287C12.6529 10.83 13.4043 10.0271 13.4043 9.03965V3.22101L7.70745 7.7864C7.11054 8.26476 6.29372 8.26476 5.69681 7.7864L0 3.22101Z"
        fill="#3F556F"
      />
    </svg>
  );
};

const PhoneIcon = () => {
  return (
    <svg
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.77731 0.697397C3.60093 0.242137 3.13592 -0.000179432 2.69153 0.129545L0.675746 0.716978C0.277171 0.834465 0 1.22119 0 1.66177C0 7.71722 4.59507 12.6272 10.2622 12.6272C10.6745 12.6272 11.0364 12.331 11.1464 11.9051L11.6961 9.75121C11.8175 9.27637 11.5908 8.7795 11.1647 8.59103L8.96566 7.61197C8.59229 7.44554 8.15935 7.56057 7.90509 7.8959L6.97966 9.10259C5.36703 8.28752 4.06135 6.89237 3.29856 5.16923L4.42786 4.18283C4.74168 3.9087 4.84934 3.44854 4.69357 3.04958L3.77731 0.699845V0.697397Z"
        fill="#3F556F"
      />
    </svg>
  );
};

const SuccessIcon = () => {
  return (
    <svg
      width="109"
      height="109"
      viewBox="0 0 109 109"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M54.5 108C84.0472 108 108 84.0472 108 54.5C108 24.9528 84.0472 1 54.5 1C24.9528 1 1 24.9528 1 54.5C1 84.0472 24.9528 108 54.5 108Z"
        stroke="#61D8AA"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.7222 54.4997L48.5555 72.3331L78.2777 42.6108"
        stroke="#61D8AA"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const RightArrowIcon = () => {
  return (
    <svg
      width="62"
      height="21"
      viewBox="0 0 62 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M52.455 19.942L61.005 11.392C61.2884 11.1085 61.4492 10.7256 61.4519 10.3275C61.4546 9.92929 61.299 9.54851 61.0194 9.26887L52.5844 0.833923C52.376 0.624736 52.1093 0.482995 51.8181 0.426667C51.527 0.37034 51.2245 0.401964 50.949 0.51753C50.6735 0.633096 50.4375 0.8274 50.2707 1.07581C50.104 1.32422 50.0141 1.61554 50.0125 1.91286L49.9673 8.90427L0.513457 9.23714L0.493094 12.2435L49.9469 11.9064L49.8974 18.8978C49.895 19.1951 49.981 19.4853 50.1444 19.7314C50.3077 19.9776 50.5412 20.1687 50.8152 20.2806C51.0891 20.3924 51.3912 20.4199 51.6831 20.3597C51.9751 20.2994 52.2437 20.154 52.455 19.942Z"
        fill="#61D8AA"
      />
    </svg>
  );
};

const CloseIcon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.414337 2.39091C-0.132281 1.84429 -0.132281 0.956583 0.414337 0.409964C0.960956 -0.136655 1.84867 -0.136655 2.39528 0.409964L7 5.01905L11.6091 0.414337C12.1557 -0.132282 13.0434 -0.132282 13.59 0.414337C14.1367 0.960956 14.1367 1.84866 13.59 2.39528L8.98095 7L13.5857 11.6091C14.1323 12.1557 14.1323 13.0434 13.5857 13.59C13.039 14.1367 12.1513 14.1367 11.6047 13.59L7 8.98095L2.39091 13.5857C1.84429 14.1323 0.956583 14.1323 0.409964 13.5857C-0.136655 13.039 -0.136655 12.1513 0.409964 11.6047L5.01905 7L0.414337 2.39091Z"
        fill="#3F556F"
      />
    </svg>
  );
};

const EditMeterIcon = () => {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0989 0.421382C11.537 -0.140461 10.6288 -0.140461 10.067 0.421382L9.29479 1.19103L11.8064 3.70265L12.5786 2.93044C13.1405 2.36859 13.1405 1.46041 12.5786 0.898564L12.0989 0.421382ZM4.42291 6.06547C4.26642 6.22196 4.14584 6.41438 4.07657 6.62731L3.31718 8.90547C3.24278 9.1261 3.30179 9.36983 3.46598 9.53658C3.63017 9.70334 3.8739 9.75978 4.09709 9.68538L6.37525 8.926C6.58562 8.85416 6.77804 8.73615 6.9371 8.57965L11.2292 4.28502L8.71498 1.77083L4.42291 6.06547ZM2.46287 1.50659C1.10316 1.50659 0 2.60975 0 3.96946V10.5371C0 11.8968 1.10316 13 2.46287 13H9.03054C10.3903 13 11.4934 11.8968 11.4934 10.5371V8.07425C11.4934 7.62016 11.1265 7.25329 10.6725 7.25329C10.2184 7.25329 9.8515 7.62016 9.8515 8.07425V10.5371C9.8515 10.9912 9.48463 11.3581 9.03054 11.3581H2.46287C2.00878 11.3581 1.64192 10.9912 1.64192 10.5371V3.96946C1.64192 3.51537 2.00878 3.1485 2.46287 3.1485H4.92575C5.37984 3.1485 5.74671 2.78164 5.74671 2.32754C5.74671 1.87345 5.37984 1.50659 4.92575 1.50659H2.46287Z"
        fill="#F8FBFF"
      />
    </svg>
  );
};

export {
  EditPhoneNumberIcon,
  EditPhoneNumberTitleIcon,
  MSGIcon,
  PhoneIcon,
  SuccessIcon,
  RightArrowIcon,
  CloseIcon,
  EditMeterIcon,
};
