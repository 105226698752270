import SortReducer from "./SortSlice";
import { configureStore } from "@reduxjs/toolkit";
import filterReducer from "./FilterSlice";
import searchReducer from "./SearchSlice";

const store = configureStore({
  reducer: { filterSlice: filterReducer, searchSlice: searchReducer,sortSlice:SortReducer },
});

export default store;
