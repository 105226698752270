import FailStatus from "../../common/Labels/FailStatus";
import NewStatus from "../../common/Labels/NewStatus";
import NotStarted from "../../common/Labels/NotStartedStatus";
import PendingStatus from "../../common/Labels/PendingStatus";
import SuccessStatus from "../../common/Labels/SuccessStatus";
import ToolTipComponent from "./ToolTipComponent";
import { Tooltip } from "@mui/material";

type Status = {
  currentStatus: number;
  description?: string;
  title?: string;
  children?: string | JSX.Element | JSX.Element[] | (() => JSX.Element);
};
const DetailsStatus = ({
  currentStatus,
  title,
  description,
  children,
}: Status) => {
  let content;
  switch (currentStatus) {
    case 0:
      content = <NewStatus>{"New"}</NewStatus>;
      break;
    case 1:
      content = (
        <NotStarted>
          {title?.includes("Washing")
            ? "Meter Search Not Started"
            : "Not on board"}
        </NotStarted>
      );
      break;
    case 2:
      content = <PendingStatus>Bad credit</PendingStatus>;
      break;
    case 3:
      content = (
        <FailStatus>
          <Tooltip
            componentsProps={{
              tooltip: {
                sx: {
                  border: "1px solid #FFAEAE",
                  backgroundColor: "#fff",
                  "& .MuiTooltip-arrow": {
                    color: "#FFAEAE",
                  },
                  borderRadius: 1,
                },
              },
            }}
            title={
              <ToolTipComponent
                title={title?.concat(" ", "failed")}
                description={description}
              />
            }
            arrow
          >
            <div>{children?.toString().concat(" ", "failed")}</div>
          </Tooltip>
        </FailStatus>
      );
      break;
    case 4:
      content = (
        <FailStatus>
          <div>{children?.toString().concat(" ", "failed")}</div>
        </FailStatus>
      );
      break;
    case 5:
      content = (
        <SuccessStatus>
          {children?.toString().concat(" ", "succeeded")}
        </SuccessStatus>
      );
      break;
    case 6:
      content = <SuccessStatus>{children}</SuccessStatus>;
      break;
    case 7:
      content = (
        <FailStatus>
          <Tooltip
            componentsProps={{
              tooltip: {
                sx: {
                  border: "1px solid #FFAEAE",
                  backgroundColor: "#fff",
                  "& .MuiTooltip-arrow": {
                    color: "#FFAEAE",
                  },
                  borderRadius: 1,
                },
              },
            }}
            title={
              <ToolTipComponent
                title={"Credit Risk"}
                description={"Due to bad credit score"}
              />
            }
            arrow
          >
            <div>High credit risk</div>
          </Tooltip>
        </FailStatus>
      );
      break;
    default:
      content = (
        <NotStarted>
          {title?.includes("Washing")
            ? "Meter Search Not Started"
            : "Not on board"}
        </NotStarted>
      );
      break;
  }
  return content;
};
export default DetailsStatus;
