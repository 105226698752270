import { backend } from "../../config/react-app-config";

interface CustomerDetailsPayload {
  currentPhoneNumber: string;
  newPhoneNumber: string;
}

export function updatePhoneNumber(payload: CustomerDetailsPayload) {
  return backend.put<CustomerDetailsPayload>(
    "/api/v1/users/update/phoneNumber",
    payload
  );
}

export function updateMeterId(newMeterId: string, serialNum: string) {
  return backend.put(`/api/v1/users/update/meterId/${serialNum}`, {
    meterId: newMeterId,
  });
}
