import { Button, TextField } from "@mui/material";
import { type Dispatch, type SetStateAction } from "react";
import { useTranslation } from "react-i18next";

type CurrentPhoneNumberProps = {
  phoneNumber: string;
  setPhoneNumber: Dispatch<SetStateAction<string>>;
  setErrMsg: Dispatch<SetStateAction<string>>;
  setShowMeters: Dispatch<SetStateAction<boolean>>;
  errMsg: string;
  handleNextClick: () => Promise<void>;
  loading: boolean;
};

const CurrentPhoneNumber = ({
  phoneNumber,
  setPhoneNumber,
  setErrMsg,
  setShowMeters,
  errMsg,
  handleNextClick,
  loading,
}: CurrentPhoneNumberProps) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col justify-center items-center bg-[#FFFFFF] w-[474px] p-10 rounded-xl shadow-2xl	gap-8">
      <span className="text-[24px] text-[#3F556F] font-normal">
        {t("changePhoneNumber.currentPhoneNumber")}
      </span>
      <TextField
        id="phone-number"
        type="number"
        required
        variant="standard"
        autoFocus
        value={phoneNumber}
        inputProps={{
          style: { textAlign: "center" },
        }}
        sx={{ width: "100%", textAlign: "center" }}
        onChange={(event) => {
          const enteredPhoneNumber = event.target.value;
          const numericPhoneNumber = enteredPhoneNumber
            .replace(/\D/g, "")
            .slice(0, 18);
          setPhoneNumber(numericPhoneNumber);
          setErrMsg("");
          setShowMeters(false);
        }}
      />
      <Button
        variant="secondary"
        color="primary"
        sx={{ paddingY: "12px", paddingX: "71px", marginTop: 5 }}
        onClick={handleNextClick}
        disabled={loading}
      >
        <span className="font-semibold text-[17px]">
          {t("changePhoneNumber.search")}
        </span>
      </Button>
      {errMsg !== "" && <span className="mt-1 text-[red]">{errMsg}</span>}
    </div>
  );
};

export default CurrentPhoneNumber;
