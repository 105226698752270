import { FontAwesomeIconProps } from "@fortawesome/react-fontawesome";

export enum SelectedFilter {
  CHANGESTATUS = "CHANGESTATUS",
}
export enum SelectedDialog {
  OPEN = "OPEN",
  CLOSE = "CLOSE",
}
// An interface for our actions
export interface SelectedAction {
  type: SelectedFilter;
  payload: Object;
}
export interface SelectedState {
  all: boolean;
  new: boolean;
  washFail: boolean;
  washSuccess: boolean;
  onboardingFail: boolean;
  onboardingSuccess: boolean;
}

export interface DialogState {
  isOpen: boolean;
  UserId?: any;
}

export interface DialogAction {
  type: SelectedDialog;
  payload: Object;
}

export enum sideBardMenu {
  Dashboard = "Dashboard",
  Submission = "Submission",
  "Faulty Submission" = "Faulty Submission",
  ChangeUserPhoneNumber = "Change user phone number",
  "Faulty Number" = "Faulty Number",
}
export type User = {
  ids: number;
  id: number;
  mobilenumber: string;
  ref_code: string;
  batch_no: string;
  email: string;
  fornavn: string;
  etternavn: string;
  personnummer: string;
  bolig: string;
  postnummer: string;
  sted: string;
  fhouisemovetype: string;
  pay_extra: string;
  velgdato: string;
  m_lernummer: string;
  from_api: string;
  status: string;
  newsletter: number;
  is_import_user: number;
  created_at: string;
  updated_at: string;
  order_by_status: number;
  referal_mobile_num: null;
  onboard_status: string | null;
  wash_status: string | null;
};
export type UserTableData = {
  users: any;
  page: number;
  totalCount: number;
  totalPageCount: number;
  result: User[];
};
export type IconProps = {
  width: number;
  hight: number;
  className: string;
  isSelected: boolean;
};
export type InitialStateType = {
  [key: string]: any;
};
export enum SortBy {
  CHANGESORT = "CHANGESORT",
}

export interface SortAction {
  type: SortBy;
  payload: Object;
}
export const initialState = {
  ids: {
    asc: false,
    desc: false,
  },
  fullname: {
    asc: false,
    desc: false,
  },
  status: {
    asc: false,
    desc: false,
  },
  email: {
    asc: false,
    desc: false,
  },
  mobilenumber: {
    asc: false,
    desc: false,
  },
  personnummer: {
    asc: false,
    desc: false,
  },
  address: {
    asc: false,
    desc: false,
  },
  updated_at: {
    asc: false,
    desc: false,
  },
};
export type CommonProps = {
  children:
    | string
    | JSX.Element
    | JSX.Element[]
    | (() => JSX.Element)
    | undefined
    | React.ReactNode[];
};
export interface UserResponse {
  id: number;
  name: string;
  email: string;
  otp: string;
  otpCreatedAt: string;
  picture: string;
  rememberToken: any;
  createdAt: string;
  updatedAt: string;
  roles: string[];
  mfaEnabled: boolean;
  mfaSecret: string;
  enabled: boolean;
  username: string;
  accountNonExpired: boolean;
  accountNonLocked: boolean;
  credentialsNonExpired: boolean;
}
export type FontAwesomeIconPropsWithOption = Omit<FontAwesomeIconProps, "icon">;
export interface LocationParams<Data> {
  pathname: string;
  state: Data;
  search: string;
  hash: string;
  key: string;
}
