import { useState } from "react";
import { getCustomerDetails } from "../pages/CustomerOverviewPage/customerDetails-api";
import { CustomerDetails } from "../pages/CustomerOverviewPage/CustomerDetails";
import { updatePhoneNumber } from "../pages/CustomerOverviewPage/changePhoneNumber-api";
import { useTranslation } from "react-i18next";
import { EditPhoneNumberTitleIcon } from "../Icons/icons";
import CurrentPhoneNumber from "./CurrentPhoneNumber";
import ChangePhoneNumberScreen from "./ChangePhoneNumber";
import ShowCustomerDetailsScreen from "./CustomerDetailScreen";
import SuccessScreen from "./SuccessScreen";
import { Loader } from "../common/Loader/Loader";

function ChangeUserPhonePage() {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [showSearchScreen, setShowSearchScreen] = useState(true);
  const [showChangePhoneNumberScreen, setShowChangePhoneNumberScreen] =
    useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [customerDetailsResponse, setCustomerDetailsResponse] =
    useState<CustomerDetails>({} as CustomerDetails);
  const [showMeters, setShowMeters] = useState(false);
  const [updatedPhoneNumber, setUpdatedPhoneNumber] = useState("");
  const [showSuccessScreen, setShowSuccessScreen] = useState(false);
  const [newPhoneNumber, setNewPhoneNumber] = useState("");
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const handleNextClick = async () => {
    setErrMsg("");
    setLoading(true);
    const isValidPhoneNumber = /^47[49]/.test(phoneNumber);
    if (isValidPhoneNumber) {
      getCustomerDetails(phoneNumber)
        .then((res) => {
          setCustomerDetailsResponse(res.data);
          setShowMeters(true);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching customer details:", error);
          setErrMsg(error.response.data);
          setLoading(false);
        });
    } else {
      setErrMsg("Please enter a valid 10-digit phone number.");
      setLoading(false);
    }
  };

  const handleConfirmClick = async () => {
    setErrMsg("");
    const isValidPhoneNumber = /^47[49]/.test(updatedPhoneNumber);
    if (isValidPhoneNumber) {
      updatePhoneNumber({
        currentPhoneNumber: phoneNumber,
        newPhoneNumber: updatedPhoneNumber,
      })
        .then(() => {
          setNewPhoneNumber(updatedPhoneNumber);
          setShowChangePhoneNumberScreen(false);
          setShowSuccessScreen(true);
        })
        .catch((error) => {
          console.error(
            "Error while updating customer details:",
            error.response.data
          );
          setErrMsg(error.response.data.message);
          console.log("errMsg :: ", errMsg);
        });
    } else {
      setErrMsg("Please enter a valid 10-digit phone number.");
    }
  };

  const getScreens = () => {
    if (showSearchScreen) {
      return (
        <>
          <CurrentPhoneNumber
            errMsg={errMsg}
            handleNextClick={handleNextClick}
            phoneNumber={phoneNumber}
            setErrMsg={setErrMsg}
            setPhoneNumber={setPhoneNumber}
            setShowMeters={setShowMeters}
            loading={loading}
          />
          {loading ? (
            <div className="mt-10">
              <Loader size="small" />
            </div>
          ) : (
            showMeters && (
              <ShowCustomerDetailsScreen
                customerDetailsResponse={customerDetailsResponse}
                setShowChangePhoneNumberScreen={setShowChangePhoneNumberScreen}
                setShowMeters={setShowMeters}
                setShowSearchScreen={setShowSearchScreen}
              />
            )
          )}
        </>
      );
    } else if (showChangePhoneNumberScreen) {
      return (
        <ChangePhoneNumberScreen
          customerDetailsResponse={customerDetailsResponse}
          errMsg={errMsg}
          handleConfirmClick={handleConfirmClick}
          setErrMsg={setErrMsg}
          setShowChangePhoneNumberScreen={setShowChangePhoneNumberScreen}
          setShowSearchScreen={setShowSearchScreen}
          setUpdatedPhoneNumber={setUpdatedPhoneNumber}
          updatedPhoneNumber={updatedPhoneNumber}
          setNewPhoneNumber={setNewPhoneNumber}
          setPhoneNumber={setPhoneNumber}
        />
      );
    } else if (showSuccessScreen) {
      return (
        <SuccessScreen
          customerDetailsResponse={customerDetailsResponse}
          newPhoneNumber={newPhoneNumber}
          phoneNumber={phoneNumber}
          setNewPhoneNumber={setNewPhoneNumber}
          setPhoneNumber={setPhoneNumber}
          setShowSearchScreen={setShowSearchScreen}
          setShowSuccessScreen={setShowSuccessScreen}
          setUpdatedPhoneNumber={setUpdatedPhoneNumber}
        />
      );
    }
  };

  return (
    <div className="flex flex-col min-h-screen p-5 bg-[#F2F7FD] w-full items-center">
      <div className="py-5 flex flex-row self-start justify-center items-center gap-x-2">
        <EditPhoneNumberTitleIcon />
        <span className="text-2xl	text-[#2B415A]">
          {t("changePhoneNumber.title")}
        </span>
      </div>
      {getScreens()}
    </div>
  );
}

export default ChangeUserPhonePage;
