import { createSlice } from "@reduxjs/toolkit";

type Action = {
  type: string;
  payload: any;
};
const initialState = {
  filterState: {
    all: true,
    New: false,
    wash_status_fail: false,
    wash_status_success: false,
    onboard_status_fail: false,
    onboard_status_success: false,
    onboard_status_manual_reading_meter: false,
  },
};
const filterSlice = createSlice({
  name: "filterSlice",
  initialState,
  reducers: {
    changeStatus(state: any, action: Action) {
      return action.payload;
    },
  },
});
export const filterSliceActions = filterSlice.actions;

export default filterSlice.reducer;
