import "react-phone-number-input/style.css";

import { Box, Button, CircularProgress } from "@mui/material";
import PhoneInput, {
  formatPhoneNumber,
  formatPhoneNumberIntl,
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";

import { AxiosError } from "axios";
import { Modal } from "../pages/Impersonation/Modal";
import { Stack } from "@mui/system";
import { saveAuditLogForImpersonation } from "../pages/Impersonation/impersonation-api";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

export const ValidationFormForImpersonation = ({ setOpenModal }: any) => {
  let initialState: any;
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState("");
  const [valid, setValid] = useState(initialState);
  const navigate = useNavigate();
  const [btnDisabled, setBtnDisabled] = useState(true);
  const functionCalls = async () => {
    try {
      if (!value) {
        setValid("Please Enter Impersonate Customer Phone Number!!");
        return;
      }
      const checkCondition =
        isPossiblePhoneNumber(value) &&
        isValidPhoneNumber(value) &&
        formatPhoneNumber(value) &&
        formatPhoneNumberIntl(value);
      if (!checkCondition) {
        setValid("Enter valid phone number");
        return;
      }

      setLoading(true);
      setOpenModal(false);
      impersonationTag();
      const body = {
        targetedImpersonator: value,
      };
      await saveAuditLogForImpersonation(body);

      setLoading(false);
    } catch (error: any | Error | AxiosError) {
      setLoading(false);
      if (error) {
        const { response } = error;
        if (response) {
          setValid("Impersonate Customer Not Found");
        } else {
          setValid(`Something goes wrong`);
        }
      }
    }
  };
  const impersonationTag = () => {
    if (value !== null) {
      navigate("/impersonation", { state: { value } });
    }
  };

  const setPhoneNumber = (e: any) => {
    setValue(e);
    setValid(initialState);
    setBtnDisabled(false);
  };
  const handlePhoneNumber = (e: any) => {
    setValue((preValue) => e);
    disAbleButton(e);
  };

  const disAbleButton = (e: string) => {
    e &&
    isPossiblePhoneNumber(e) &&
    isValidPhoneNumber(e) &&
    formatPhoneNumber(e) &&
    formatPhoneNumberIntl(e)
      ? setPhoneNumber(e)
      : arrowfun();
  };
  const arrowfun = () => {
    setValid("Enter valid phone number");
    setBtnDisabled(true);
  };
  return (
    <Modal setOpenModal={setOpenModal}>
      <div className="title">
        <h1 className="capitalize">Enter Impersonation target Number</h1>
      </div>
      <div className="mt-10 px-6">
        {loading && (
          <Box className="flex place-content-center">
            <CircularProgress />
          </Box>
        )}
        <PhoneInput
          placeholder="Enter phone number"
          value={value}
          defaultCountry="NO"
          onChange={(e) => handlePhoneNumber(e)}
          error={
            value
              ? isValidPhoneNumber(value)
                ? undefined
                : "Invalid phone number"
              : "Phone number required"
          }
          containerstyle={{
            border: "10px solid black",
          }}
          inputstyle={{
            background: "lightblue",
          }}
          required
        />
        <div
          style={{
            marginLeft: "15%",

            color: "red",
          }}
          className="pt-2"
        >
          {valid}
        </div>
      </div>
      <div>
        <Stack direction={"row"} justifyContent={"center"} mt={6} spacing={12}>
          <Button
            variant="cancel"
            onClick={() => {
              setOpenModal(false);
            }}
          >
            Cancel
          </Button>
          <Button variant="secondary" onClick={functionCalls}>
            Continue
          </Button>
        </Stack>
      </div>
    </Modal>
  );
};
