import { useTranslation } from "react-i18next";
import { RightArrowIcon, SuccessIcon } from "../Icons/icons";
import { Button } from "@mui/material";
import { CustomerDetails } from "../pages/CustomerOverviewPage/CustomerDetails";
import { type Dispatch, type SetStateAction } from "react";

type SuccessScreenProps = {
  customerDetailsResponse: CustomerDetails;
  phoneNumber: string;
  newPhoneNumber: string;
  setShowSearchScreen: Dispatch<SetStateAction<boolean>>;
  setShowSuccessScreen: Dispatch<SetStateAction<boolean>>;
  setPhoneNumber: Dispatch<SetStateAction<string>>;
  setNewPhoneNumber: Dispatch<SetStateAction<string>>;
  setUpdatedPhoneNumber: Dispatch<SetStateAction<string>>;
};

const SuccessScreen = ({
  customerDetailsResponse,
  phoneNumber,
  newPhoneNumber,
  setShowSearchScreen,
  setShowSuccessScreen,
  setPhoneNumber,
  setNewPhoneNumber,
  setUpdatedPhoneNumber,
}: SuccessScreenProps) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col justify-center items-center bg-[#FFFFFF] w-[474px] p-10 rounded-xl shadow-2xl">
      <span className="text-[24px] text-[#3F556F] font-semibold">
        {customerDetailsResponse.name}
      </span>
      <div className="mt-4">
        <SuccessIcon />
      </div>
      <div className="flex flex-row justify-center items-center gap-5 mt-7">
        <div className="flex flex-col gap-2">
          <span className="font-semibold text-[16px] text-[#3F556F]">
            {t("changePhoneNumber.old_number")}
          </span>
          <span className="font-normal text-[18px] text-[#3F556F]">
            {phoneNumber}
          </span>
        </div>
        <RightArrowIcon />
        <div className="flex flex-col gap-2">
          <span className="font-semibold text-[16px] text-[#3F556F]">
            {t("changePhoneNumber.new_number")}
          </span>
          <span className="font-normal text-[18px] text-[#3F556F]">
            {newPhoneNumber}
          </span>
        </div>
      </div>
      <Button
        variant="secondary"
        color="primary"
        sx={{ paddingY: "12px", paddingX: "71px", marginTop: 5 }}
        onClick={() => {
          setShowSearchScreen(true);
          setShowSuccessScreen(false);
          setPhoneNumber("");
          setNewPhoneNumber("");
          setUpdatedPhoneNumber("");
        }}
      >
        <span className="font-semibold text-[17px]">
          {t("changePhoneNumber.go_back")}
        </span>
      </Button>
    </div>
  );
};

export default SuccessScreen;
