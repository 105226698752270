import { Button, TextField } from "@mui/material";
import { CustomerDetails } from "../pages/CustomerOverviewPage/CustomerDetails";
import { useTranslation } from "react-i18next";
import { type Dispatch, type SetStateAction } from "react";

type ChangePhoneNumberScreenProps = {
  customerDetailsResponse: CustomerDetails;
  updatedPhoneNumber: string;
  setUpdatedPhoneNumber: Dispatch<SetStateAction<string>>;
  setErrMsg: Dispatch<SetStateAction<string>>;
  handleConfirmClick: () => Promise<void>;
  setShowChangePhoneNumberScreen: Dispatch<SetStateAction<boolean>>;
  setShowSearchScreen: Dispatch<SetStateAction<boolean>>;
  errMsg: string;
  setPhoneNumber: Dispatch<SetStateAction<string>>;
  setNewPhoneNumber: Dispatch<SetStateAction<string>>;
};

const ChangePhoneNumberScreen = ({
  customerDetailsResponse,
  updatedPhoneNumber,
  setErrMsg,
  setUpdatedPhoneNumber,
  handleConfirmClick,
  setShowChangePhoneNumberScreen,
  setShowSearchScreen,
  errMsg,
  setPhoneNumber,
  setNewPhoneNumber,
}: ChangePhoneNumberScreenProps) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col justify-center items-center bg-[#FFFFFF] w-[474px] p-10 rounded-xl shadow-2xl">
      <span className="text-[24px] text-[#3F556F] font-semibold">
        {customerDetailsResponse.name}
      </span>
      <span className="text-[16px] text-[#3F556F] font-semibold mt-6">
        {t("changePhoneNumber.current_number")}
      </span>
      <span className="text-[18px] text-[#3F556F] font-normal mt-2">
        {customerDetailsResponse.phoneNumber}
      </span>
      <span className="text-[16px] text-[#3F556F] font-semibold mt-10">
        {t("changePhoneNumber.new_number")}
      </span>
      <TextField
        id="phone-number"
        type="number"
        required
        variant="standard"
        autoFocus
        value={updatedPhoneNumber}
        inputProps={{
          style: { textAlign: "center" },
          maxLength: 10,
        }}
        sx={{ width: "100%", textAlign: "center", marginTop: 2 }}
        onChange={(event) => {
          const enteredPhoneNumber = event.target.value;
          const numericPhoneNumber = enteredPhoneNumber
            .replace(/\D/g, "")
            .slice(0, 10);
          setUpdatedPhoneNumber(numericPhoneNumber);
          setErrMsg("");
        }}
      />
      <Button
        variant="secondary"
        color="primary"
        sx={{ paddingY: "12px", paddingX: "71px", marginTop: 5 }}
        onClick={handleConfirmClick}
      >
        <span className="font-semibold text-[17px]">
          {t("changePhoneNumber.confirm")}
        </span>
      </Button>
      <Button
        sx={{
          backgroundColor: "transparent",
          color: "red",
          marginTop: 3,
        }}
        onClick={() => {
          setShowChangePhoneNumberScreen(false);
          setShowSearchScreen(true);
          setUpdatedPhoneNumber("");
          setErrMsg("");
          setPhoneNumber("");
          setNewPhoneNumber("");
        }}
      >
        <span className=" text-[17px] text-[#EB6767]">
          {t("changePhoneNumber.cancel")}
        </span>
      </Button>
      {errMsg !== "" && <span className="mt-1 text-[red]">{errMsg}</span>}
    </div>
  );
};

export default ChangePhoneNumberScreen;
