import CheckIcon from "@mui/icons-material/Check";
import { CommonProps } from "../../pages/OldKunde/Types";
import LabelContainer from "./Container";
import { Typography } from "@mui/material";

const SuccessStatus = ({ children }: CommonProps) => {
  return (
    <LabelContainer>
      <CheckIcon color={"success"} sx={{ mr: 0.5, fontSize: 14 }} />
      <Typography fontSize={14} variant="oldDashboardFont" color={"#54BC4E"}>
        {children}
      </Typography>
    </LabelContainer>
  );
};
export default SuccessStatus;
