import {
  Avatar,
  CssBaseline,
  Drawer,
  IconButton,
  InputAdornment,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import {
  DialogAction,
  DialogState,
  SelectedDialog,
  UserResponse,
} from "../../pages/OldKunde/Types";
import { Outlet, useNavigate } from "react-router";
import { Provider, useDispatch } from "react-redux";
import React, {
  Dispatch,
  createContext,
  useCallback,
  useEffect,
  useReducer,
  useState,
} from "react";

import { AiOutlineSearch } from "react-icons/ai";
import ClearIcon from "@mui/icons-material/Clear";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactComponent as Logo } from "../../assets/Logo dark.svg";
import { OnPageLoaderProvider } from "../../components/OnPageLoader";
import { SideMenu } from "../../pages/OldKunde/SideMenu";
import { getUserDetailsByToken } from "../../pages/OldKunde/Api";
import { searchAction } from "../../store/SearchSlice";
import store from "../../store";

const initialStateOfDialog: DialogState = {
  isOpen: false,
};

const dialogReducer = (
  state: DialogState,
  action: DialogAction
): DialogState => {
  switch (action.type) {
    case SelectedDialog.OPEN: {
      return { isOpen: true, ...action.payload };
    }
    case SelectedDialog.CLOSE: {
      return { isOpen: false };
    }
    default:
      return state;
  }
};

export const DialogContext = createContext<{
  dialogState: DialogState;
  dialogDispatch: Dispatch<DialogAction>;
}>({ dialogState: initialStateOfDialog, dialogDispatch: () => null });

const drawerWidth = 240;
const OldKundeLayoutMain = () => {
  const [userDialog, userDialogDispatch]: any = useReducer<any>(
    dialogReducer,
    initialStateOfDialog
  );
  const [query, setQuery] = useState("");

  const [userResponse, setUserResponse] = useState({
    name: "",
  } as UserResponse);
  // const { searchDispatch } = useContext(SearchContext);
  const searchDispatch = useDispatch();
  const handleSearch = useCallback((text) => {
    searchDispatch(searchAction.findQuery({ searchQuery: text }));
    setQuery(text);
    // Call your search function here
  }, []);
  useEffect(() => {
    getUserDetailsByToken().then((response) => {
      setUserResponse(response.data);
    });
  }, []);
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleSearch(query);
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [query, handleSearch]);

  const onClearText = (e: any) => {
    searchDispatch(searchAction.findQuery({ searchQuery: "" }));
    setQuery("");
  };
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <DialogContext.Provider
        value={{
          dialogState: userDialog,
          dialogDispatch: userDialogDispatch,
        }}
      >
        <Drawer
          sx={{
            width: drawerWidth,
            height: "100%",
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
          variant="permanent"
          anchor="left"
        >
          <Stack
            display={"flex"}
            sx={{ height: "100%" }}
            justifyContent={"space-between"}
          >
            <div>
              <Toolbar className="bg-#E3E3E3-50 ">
                <Stack spacing={2.8} pt={2.4} pb={4}>
                  <Logo />
                  <Typography
                    fontSize={22}
                    color={"#656A84"}
                    fontFamily={"Inter"}
                    fontWeight={500}
                    lineHeight={1}
                  >
                    Backoffice
                  </Typography>
                </Stack>
              </Toolbar>
              <Stack alignItems={"center"} pb={1}>
                <TextField
                  placeholder="Search"
                  inputProps={{
                    style: { paddingTop: 8, paddingBottom: 8 },
                  }}
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AiOutlineSearch />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <IconButton
                        onClick={onClearText}
                        sx={{ visibility: query ? "visible" : "hidden" }}
                      >
                        <ClearIcon
                          sx={{ visibility: query ? "visible" : "hidden" }}
                        />
                      </IconButton>
                    ),
                  }}
                  sx={{
                    width: 202,
                    padding: 0,

                    "& .MuiOutlinedInput-root": {
                      "& > fieldset": {
                        borderColor: "#E3E3E3",
                        borderRadius: 16,
                        padding: 0,
                      },
                    },
                    "& .MuiOutlinedInput-root.Mui-focused": {
                      "& > fieldset": {
                        borderColor: "#E3E3E3",
                        outline: 0,
                        borderRadius: 16,
                      },
                      "& .MuiOutlinedInput-root:focus": {
                        "& > fieldset": {
                          borderColor: "#E3E3E3",
                          borderRadius: 16,
                        },
                      },
                      "& .MuiOutlinedInput-root:hover": {
                        "& > fieldset": {
                          borderColor: "#E3E3E3",
                          borderRadius: 16,
                        },
                      },
                      "&:hover fieldset": {
                        borderColor: "grey",
                      },
                    },
                  }}
                  variant="outlined"
                />
              </Stack>
              <SideMenu />
            </div>
            <Stack justifyContent={"center"} pb={8} alignItems={"center"}>
              <Avatar sx={{ width: 96, height: 96 }}>
                {" "}
                {userResponse.name.charAt(0)}
              </Avatar>
              <Typography pt={1} sx={{ wordBreak: "break-all" }}>
                {userResponse?.name}
              </Typography>
              <BottomButton />
            </Stack>
          </Stack>
        </Drawer>
        {/* <main> */}
        <OnPageLoaderProvider>
          <Outlet />
        </OnPageLoaderProvider>
        {/* </main> */}
      </DialogContext.Provider>
    </Box>
  );
};

const BottomButton = () => {
  const navigation = useNavigate();
  const gotoHome = () => {
    navigation("/");
  };
  return (
    <Stack pt={2.63} flexDirection={"row"}>
      <IconButton
        aria-label="delete"
        size="large"
        sx={{
          border: "1px solid #104EEE",
          borderRadius: 2,
          marginRight: 2.4,
        }}
      >
        <FontAwesomeIcon color="#104EEE" icon={"gear"} />
      </IconButton>
      <IconButton
        aria-label="delete"
        size="large"
        sx={{ border: "1px solid #EB6767", borderRadius: 2 }}
        onClick={gotoHome}
      >
        <FontAwesomeIcon color="#EB6767" icon={"right-from-bracket"} />
      </IconButton>
    </Stack>
  );
};
const OldKundeLayout = () => {
  return (
    <Provider store={store}>
      <OldKundeLayoutMain />
    </Provider>
  );
};
export default OldKundeLayout;
