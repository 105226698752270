import {
  Card,
  Skeleton,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import { filterSliceActions } from "../../store/FilterSlice";
import { getStatusCount } from "./Api";
import { makeStyles } from "@mui/styles";
import styled from "@emotion/styled";
import { useQuery } from "react-query";

const commonTogglebtnStyle = { p: 0, flex: 1, display: "flex", height: "80%" };
const root = {
  color: "white",
  boxShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.25) !important",
};
const useStyles = makeStyles({
  all: {
    background: "linear-gradient(#3D4B60, #3D4B60)",
    ...root,
  },
  new: {
    background: "linear-gradient(#104EEE,#104EEE)",
    ...root,
  },
  washFail: {
    background: "linear-gradient(#EB6767,#EB6767)",
    ...root,
  },
  washSuccess: {
    background: "linear-gradient(#54C559,#54C559)",
    ...root,
  },
  onboardingFail: {
    background: "linear-gradient(#EB6767,#EB6767)",
    ...root,
  },
  onboardingSuccess: {
    background: "linear-gradient(#54C559,#54C559)",
    ...root,
  },
  onboardingManualReadingMeter: {
    background: "linear-gradient(#008080,#008080)",
    ...root,
  },
});

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }: any) => ({
  "& .MuiToggleButtonGroup-grouped": {
    margin: theme.spacing(1),
    border: 0,
    "&.Mui-disabled": {
      border: 0,
    },
    "&:not(:first-of-type)": {
      borderRadius: theme.shape.borderRadius,
    },
    "&:first-of-type": {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));

const FilterBar = () => {
  const classes = useStyles();
  const filterState = useSelector(
    (store: any) => store.filterSlice.filterState
  );
  const filterDispatch = useDispatch();
  const [formats, setFormats] = useState(() => [
    "all",
    // "new",
    // "wash fail",
    // "wash success",
    // "onboarding fail",
    // "onboarding success",
  ]);
  const { data, isLoading }: any = useQuery("FilterCounts", {
    queryFn: getStatusCount,
    refetchIntervalInBackground: false,
    retryDelay: 3,
    // refetchInterval: 3 * 1000 * 60, //3 minutes
  });

  useEffect(() => {
    setFormats((preFormats: string[]) => {
      if (
        (!preFormats.includes("all") && formats.includes("all")) ||
        formats.length === 0
      ) {
        return ["all"];
      }
      if (preFormats.includes("all") && formats.length > 1) {
        return formats.filter((singleItem) => singleItem !== "all");
      }

      return formats;
    });
    let defaultFalse = {
      all: false,
      New: false,
      wash_status_fail: false,
      wash_status_success: false,
      onboard_status_fail: false,
      onboard_status_success: false,
      onboard_status_manual_reading_meter: false,
    };
    formats.forEach((singleSingle: string) => {
      defaultFalse = { ...defaultFalse, [singleSingle]: true };
    });

    filterDispatch(
      filterSliceActions.changeStatus({ filterState: defaultFalse })
    );
  }, []);

  const handleFormat = (
    event: React.MouseEvent<HTMLElement>,
    newFormats: string[]
  ) => {
    setFormats((preFormats: string[]) => {
      if (
        (!preFormats.includes("all") && newFormats.includes("all")) ||
        newFormats.length === 0
      ) {
        newFormats = ["all"];
        return newFormats;
      }
      if (preFormats.includes("all") && newFormats.length > 1) {
        newFormats = newFormats.filter((singleItem) => singleItem !== "all");
        return newFormats;
      }

      return newFormats;
    });
    let defaultFalse = {
      all: false,
      New: false,
      wash_status_fail: false,
      wash_status_success: false,
      onboard_status_fail: false,
      onboard_status_success: false,
      onboard_status_manual_reading_meter: false,
    };
    newFormats.forEach((singleSingle: string) => {
      defaultFalse = { ...defaultFalse, [singleSingle]: true };
    });

    filterDispatch(
      filterSliceActions.changeStatus({ filterState: defaultFalse })
    );
  };

  return (
    <div className="pt-5">
      <Card
        className="grid grid-cols-7 "
        sx={{
          height: "100%",
          bgcolor: "#FFFFFF",
          mr: 1,
          boxShadow: " 0px 4px 4px rgba(0, 0, 0, 0.25)",
          borderRadius: "10px",
        }}
      >
        <Stack className="flex flex-col items-center justify-center flex-1 col-start-1 pl-5">
          <div className="py-2">
            <Typography
              fontFamily={"Inter"}
              fontWeight={600}
              fontSize={"1.375rem"}
              m={0}
              p={0}
              color={"#3D4B60"}
            >
              Filter status
            </Typography>
            <Typography
              fontFamily={"Inter"}
              fontWeight={400}
              fontSize={"0.75rem"}
              lineHeight={2}
              color={"#3D4B60"}
            >
              Filter out status by clicking buttons
            </Typography>
          </div>
        </Stack>
        {!isLoading ? (
          <>
            <StyledToggleButtonGroup
              value={formats}
              onChange={handleFormat}
              className="col-span-6 col-start-2"
              aria-label="text formatting"
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                "& > *:has(:last-child)": {
                  marginInline: 1,
                  borderRadius: 3,
                },
              }}
              size="large"
            >
              <ToggleButton
                className="flex flex-col items-center justify-center col-start-2 border rounded-xl "
                value="all"
                classes={{ selected: classes.all }}
                sx={{
                  ...commonTogglebtnStyle,
                }}
                aria-label="bold"
              >
                <Typography
                  variant="labelForFilter"
                  color={filterState.all ? "#fff" : "#3D4B60"}
                >
                  All
                </Typography>
                <Typography
                  variant={filterState.all ? "selectedShowCount" : "showCount"}
                  color={filterState.all ? "#fff" : "#3D4B60"}
                >
                  {data?.data.totalCount}
                </Typography>
              </ToggleButton>
              <ToggleButton
                classes={{ selected: classes.new }}
                className="flex flex-col items-center justify-center col-start-2 border filterAll rounded-xl"
                color="success"
                sx={{ ...commonTogglebtnStyle }}
                value="New"
                aria-label="italic"
              >
                <Typography
                  color={filterState.New ? "#fff" : "#104EEE"}
                  variant="labelForFilter"
                >
                  New
                </Typography>
                <Typography
                  color={filterState.New ? "#fff" : "#104EEE"}
                  variant={filterState.New ? "selectedShowCount" : "showCount"}
                >
                  {data?.data.newUser}
                </Typography>
              </ToggleButton>
              <ToggleButton
                className="flex flex-col items-center justify-center col-start-2 border filterAll rounded-xl "
                classes={{ selected: classes.washFail }}
                sx={{ ...commonTogglebtnStyle }}
                value="wash_status_fail"
                aria-label="underlined"
              >
                <Typography
                  color={filterState.wash_status_fail ? "#fff" : "#EB6767"}
                  variant="labelForFilter"
                >
                  Washing failed
                </Typography>
                <Typography
                  color={filterState.wash_status_fail ? "#fff" : "#EB6767"}
                  variant={
                    filterState.wash_status_fail
                      ? "selectedShowCount"
                      : "showCount"
                  }
                >
                  {data?.data?.washingFailed}
                </Typography>
              </ToggleButton>
              <ToggleButton
                className="flex flex-col items-center justify-center col-start-2 border filterAll rounded-xl "
                classes={{ selected: classes.washSuccess }}
                sx={{ ...commonTogglebtnStyle }}
                value="wash_status_success"
                aria-label="italic"
              >
                <Typography
                  color={filterState.wash_status_success ? "#fff" : "#54C559"}
                  variant="labelForFilter"
                >
                  Wash OK <br />
                  On-boarding pending{" "}
                </Typography>
                <Typography
                  color={filterState.wash_status_success ? "#fff" : "#54C559"}
                  variant={
                    filterState.wash_status_success
                      ? "selectedShowCount"
                      : "showCount"
                  }
                >
                  {data?.data.washingSucces}
                </Typography>
              </ToggleButton>
              <ToggleButton
                className="flex flex-col items-center justify-center col-start-2 border filterAll rounded-xl "
                classes={{ selected: classes.onboardingFail }}
                sx={{ ...commonTogglebtnStyle }}
                value="onboard_status_fail"
                aria-label="italic"
              >
                <Typography
                  color={filterState.onboard_status_fail ? "#fff" : "#EB6767"}
                  variant="labelForFilter"
                >
                  On-boarding failed
                </Typography>
                <Typography
                  color={filterState.onboard_status_fail ? "#fff" : "#EB6767"}
                  variant={
                    filterState.onboard_status_fail
                      ? "selectedShowCount"
                      : "showCount"
                  }
                >
                  {data?.data.onboardingFailed}
                </Typography>
              </ToggleButton>
              <ToggleButton
                className="flex flex-col items-center justify-center col-start-2 border filterAll rounded-xl "
                sx={{ ...commonTogglebtnStyle }}
                value="onboard_status_success"
                classes={{ selected: classes.onboardingSuccess }}
                aria-label="italic"
              >
                <Typography
                  color={
                    filterState.onboard_status_success ? "#fff" : "#54C559"
                  }
                  variant="labelForFilter"
                >
                  On-boarding succeeded
                </Typography>
                <Typography
                  color={
                    filterState.onboard_status_success ? "#fff" : "#54C559"
                  }
                  variant={
                    filterState.onboard_status_success
                      ? "selectedShowCount"
                      : "showCount"
                  }
                >
                  {data?.data.onboardingSuccess}
                </Typography>
              </ToggleButton>
              <ToggleButton
                className="flex flex-col items-center justify-center col-start-2 border filterAll rounded-xl "
                sx={{ ...commonTogglebtnStyle }}
                value="onboard_status_manual_reading_meter"
                classes={{ selected: classes.onboardingManualReadingMeter }}
                aria-label="italic"
              >
                <Typography
                  color={
                    filterState.onboard_status_manual_reading_meter
                      ? "#FFFFFF"
                      : "#008080"
                  }
                  variant="labelForFilter"
                >
                  Manual Reading Meter
                </Typography>
                <Typography
                  color={
                    filterState.onboard_status_manual_reading_meter
                      ? "#FFFFFF"
                      : "#008080"
                  }
                  variant={
                    filterState.onboard_status_manual_reading_meter
                      ? "selectedShowCount"
                      : "showCount"
                  }
                >
                  {data?.data.onboardingManualReadingMeter}
                </Typography>
              </ToggleButton>
            </StyledToggleButtonGroup>
          </>
        ) : (
          <div className="col-span-6 col-start-2 py-2">
            {" "}
            <div className="flex items-center h-28 ">
              <Skeleton
                variant="rectangular"
                height={"100%"}
                width={"16%"}
                className="flex flex-col items-center justify-center col-start-2 mx-1 border filterAll rounded-xl "
              />
              <Skeleton
                variant="rectangular"
                height={"100%"}
                width={"16%"}
                className="flex flex-col items-center justify-center col-start-2 mx-1 border filterAll rounded-xl "
              />
              <Skeleton
                variant="rectangular"
                height={"100%"}
                width={"16%"}
                className="flex flex-col items-center justify-center col-start-2 mx-1 border filterAll rounded-xl "
              />
              <Skeleton
                variant="rectangular"
                height={"100%"}
                width={"16%"}
                className="flex flex-col items-center justify-center col-start-2 mx-1 border filterAll rounded-xl "
              />
              <Skeleton
                variant="rectangular"
                height={"100%"}
                width={"16%"}
                className="flex flex-col items-center justify-center col-start-2 mx-1 border filterAll rounded-xl "
              />
              <Skeleton
                variant="rectangular"
                height={"100%"}
                width={"16%"}
                className="flex flex-col items-center justify-center col-start-2 mx-1 border filterAll rounded-xl "
              />
            </div>
          </div>
        )}
      </Card>
    </div>
  );
};
export default FilterBar;
