import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FontAwesomeIconPropsWithOption } from "../pages/OldKunde/Types";

const EditIcon = ({ size = "lg" ,...res}: FontAwesomeIconPropsWithOption) => {
  return (
    <FontAwesomeIcon
      style={{
        backgroundColor: "#104EEE",
        paddingLeft: 6,
        paddingRight: 3.5,
        paddingTop:2.5,
        paddingBottom:3,
        borderRadius: 4,
      }}
    
      size={size}
      color="#fff"
      {...res}
      icon={"pen-to-square"}
    />
  );
};
export default EditIcon;
