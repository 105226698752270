import { REFRESH_TOKEN_LIFE, TOKEN_LIFE } from "../../App/auth";

import { OAuthToken } from "../../App/AuthType";
import { res } from "../../mocks/res";
import { rest } from "msw";

const mockToken: OAuthToken = {
  access_token: "12345",
  refresh_token: "56789",
  expires_in: TOKEN_LIFE,
  refresh_token_expires_in: REFRESH_TOKEN_LIFE,
};

export const handlers = [
  rest.post("/oauth/token", (req, _, ctx) => {
    return res(ctx.json(mockToken));
  }),
  rest.post("/revoke-token", (req, _, ctx) => {
    return res();
  }),
];
