import React, { CSSProperties, createContext, useContext } from "react";

import { LoaderProps } from "../common/Loader/Loader";

type LoaderContextActions = {
  showLoader: (state: boolean) => void;
  open: boolean;
};

const LoaderBarContext = createContext({} as LoaderContextActions);


const OnPageLoaderProvider: React.FC<LoaderProps> = ({
  size = "large",
  children,
}) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const override: CSSProperties = {
    display: "block",
    margin: "0 auto",
    transform: "translate(0, -50%)",
    borderColor: "red",
    position: "absolute",
    zIndex:10000000000
  };
  let loaderSize;
  switch (size) {
    case "large":
      loaderSize = 150;
      break;

    case "small":
      loaderSize = 100;
      break;

    case "button":
      loaderSize = 30;
      break;

    default:
      break;
  }
  const showLoader = (state: boolean) => {
    // setMessage(text);
    // setTypeColor(color);
    if (!state) {
      setTimeout(() => {
        setOpen(state);
      }, 1000);
    } else {
      setOpen(state);
    }
  };

  return (
    <LoaderBarContext.Provider value={{ showLoader, open }}>
      {children}
    </LoaderBarContext.Provider>
  );
};

const useOnPageLoader = (): LoaderContextActions => {
  const context = useContext(LoaderBarContext);

  if (!context) {
    throw new Error("useLoader must be used within an OnPageLoader");
  }

  return context;
};

export { OnPageLoaderProvider, useOnPageLoader };
