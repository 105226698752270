import * as Yup from "yup";

import { Alert, AlertColor, Snackbar } from "@mui/material";
import {
  getIsMfa,
  verifyOtpforMfa,
} from "../pages/Impersonation/impersonation-api";
import { useEffect, useState } from "react";

import { AxiosError } from "axios";
import { Form } from "../common/Forms/Form";
import { Input } from "../common/Forms/Input";
import { Loader } from "../common/Loader/Loader";
import { Modal } from "../pages/Impersonation/Modal";

export const MfaComponent = ({ setOpenModal, closeMfaModel }: any) => {
  const [responseState, setResponseState] = useState({
    isMfaExists: false,
    mfaCode: "",
    qrCode: "",
  });
  const [snackBar, setSnackBar] = useState({
    state: false,
    status: "error",
    message: "",
  });

  const [loading, setLoading] = useState(false);

  const fetchMfa = async () => {
    try {
      setLoading(true);
      const response = await getIsMfa(1);

      setTimeout(() => {
        setLoading(false);
        setResponseState(response.data);
      }, 1000);

      // console.log(response.data);
    } catch (error) {
      setTimeout(() => {
        setLoading(false);
        closeMfaModel(false);
      }, 1000);
      setSnackBar((prevState) => {
        return {
          ...prevState,
          state: true,
          status: "error",
          message: "Failed to open MFA",
        };
      });
      // console.log({ error });
    }
  };

  const handleClose = (event: any, reason: string) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBar((prevState) => {
      return {
        ...prevState,
        state: false,
      };
    });
  };

  useEffect(() => {
    // sendOtp();
    fetchMfa();
  }, []);

  // useEffect(() => {
  //   const unloadCallback = (event: any) => {
  //     event.preventDefault();
  //     event.returnValue = "";
  //     return "";
  //   };
  //   sendOtp();

  //   window.addEventListener("beforeunload", unloadCallback);
  //   return () => window.removeEventListener("beforeunload", unloadCallback);
  // }, []);
  //"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAV4AAAFeAQAAAADlUEq3AAADGElEQVR4Xu2ZPZbbMAyEsW+LLXMEHUVHo46mo+gIW7rwM4IZcC0wph2nzqCwJPKTizF+afP37Wp/rrwwwdUEVxNcTXC1/wO+Ge326X6sWP1euGW2+odfuPkpeArjzjdQtm43+3LfLahfWM4LEcETOLQMChcjFXJD/MU3PuVvIPgFTN9MgbeEsSf4DTi90aw7ZTisw0UFv4DjI6KbqXAzRLfnq3tkxIfoFlxgo2H7SLmHCzcFT+G7QdItdI4gR0YEVLcF1+27zrF969T1Cw2NIch3czwN0S24wLHwvewtvbH1bcsnLq4lugUPMC2cMozv0FOxHU8G1XNP8AMMLRc88ZIuumWQO/zWMIEInsFwUVtT2RQ4ojtDPn2zqy74EWYxSbl7KkQdjibQWnYygp/BV0OPjLsDiTEUjgu+YW+WpUXwHA5vDLj1sO750XKGY43eW0kFggvsOJOClnDRdsYz+hrkxzExCh5h+CZ1hqeihTHU4TD65t7ICJ7Cjv6ZhwQ8ZtkBo9BkthT8BKak6PcMrTI+F56SUvUzaQqewKGlMSPmBNJjneWY8IpYFzyBHcfwlnWDGZGlhZRzeFud2VLwA5y+uaWn2kf++cPuD0HOTuasKYIHGMkPT5Cb60d/1Xno4siWgqewsQ5zxKWysKzKP35bXVTwELDhho3JD4nxwjktLNJkEBjlShYVXODe9oXOcbv1bXzDYQ1BzvwoeA5HPHPd4JTXngovyJaWv8FQgASfMKqI3YtJbvMdd05t+CLBU9jphtSyN4FBsdDwoGohI3gCk8I6epcGnVGOs41ObBM8h6lzuCHKMdcA138eI02OLir4B85UyCoCgZERe40Gj/x4NoGCBziNTtmntph0z5DHb1B+FMEnfEs1+19B7KZRWrKpZidTdBY8wBARyQ9Bbjjcg6dmS0jV49VTZ8EDfEEEd6cE7Jg5zhkOIS/4BYzotvVnhsMiYXqq4L/A3EY83x2W/26gvRH8BI4PTzgvZjxfOXJqo98KnsJG676J26UrG4kRU5vgZ/C7Jria4GqCqwmuJrjav8G/AcYf1wxPg4zFAAAAAElFTkSuQmCC"
  const MfaSchema = Yup.object().shape({
    enteredOtp: Yup.string()
      .required()
      .matches(/^[0-9]{1,6}$/g, "Enter proper Otp!!!"),
  });
  const initialValues = {
    enteredOtp: "",
  };
  function isAxiosError(candidate: unknown): candidate is AxiosError {
    if (
      candidate &&
      typeof candidate === "object" &&
      "isAxiosError" in candidate
    ) {
      return true;
    }
    return false;
  }

 

  const verifyOtp = async (data: any) => {
    // console.log(otpNumber);
    // console.log(data);

    if (!data.enteredOtp) {
      // console.log("Please enter proper Otp");
    } else {
      try {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        const response: any = await verifyOtpforMfa(String(data.enteredOtp));

        if (response.data.valid) {
          setSnackBar((prevState) => {
            return {
              ...prevState,
              state: true,
              status: "success",
              message: response.data.message,
            };
          });

          setTimeout(() => {
            setOpenModal(false);
          }, 1000);
        } else {
          setSnackBar((prevState) => {
            return {
              ...prevState,
              state: true,
              status: "error",
              message: response.data.message,
            };
          });
          
        }
      } catch (error: unknown) {
        // console.log(error);
        setSnackBar((prevState) => {
          return {
            ...prevState,
            state: true,
            status: "error",
            message: "Something goes wrong",
          };
        });
        if (isAxiosError(error)) {
          // console.log(`Catched error code "${error.code}".`);
        }
      }
    }
  };

  const onSubmit = (data: any) => {
    // console.log(data)
    verifyOtp(data);
  };
  return (
    <>
      <Modal setOpenModal={setOpenModal}>
        {loading && (
          <div className="flex justify-center">
            <Loader />
          </div>
        )}
        {!loading && (
          <>
            {!responseState.isMfaExists && (
              <div className="flex justify-center">
                <img className="w-10/12" src={responseState.qrCode} alt="" />
              </div>
            )}
            <div className="px-5">
              {!responseState.isMfaExists && (
                <p>Scan to get your authentication code.</p>
              )}
              {responseState.isMfaExists && (
                <>
                  <p>
                    Please enter authentication code from <br /> your device.
                  </p>
                </>
              )}

              <br />
              <hr />
              <br />
              <Form
                onSubmit={onSubmit}
                buttonText={"Verify OTP"}
                initialValues={initialValues}
                validationSchema={MfaSchema}
                onCancel={() => {
                  closeMfaModel(false);
                }}
                buttonContainer={"flex justify-center space-x-4 relative"}
              >
                <Input
                  // disabled={snackBar.status === "success"}
                  name="enteredOtp"
                  label={"Please Enter OTP"}
                />
               
              </Form>
              {/* {!loading && (
                <TextField
                  type="number"
                  placeholder="Enter Otp"
                  helperText={"Hello"}
                  value={otpNumber}
                  onChange={(
                    event: React.ChangeEvent<
                      HTMLInputElement | HTMLTextAreaElement
                    >
                  ) => validateOtp(event)}
                  inputProps={{ pattern: "^[0-9]{1,6}$" }}
                />
              )} */}
            </div>

            {/* <div
          style={{
            marginLeft: "15%",

            color: "red",
          }}
          className="pt-2"
        >
          {valid}
        </div> */}
            {/* {loading && (
          <Box className="flex place-content-center">
            <CircularProgress />
          </Box>
        )} */}
            {/* <div className="flex justify-between footer">
        <button id="cancelBtn" onClick={getMfaQr}>
        generate QR{" "}
        </button>
        <button onClick={verifyOtp}>Verify OTP</button>
      </div> */}
          </>
        )}
      </Modal>
      <Snackbar
        open={snackBar.state}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={handleClose}
      >
        <Alert severity={snackBar.status as AlertColor} sx={{ width: "100%" }}>
          {snackBar.message}
        </Alert>
      </Snackbar>
    </>
  );
};
