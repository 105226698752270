import { Card, CardContent, Stack, Typography } from "@mui/material";
import { OLD_KUNDE_DASHBOARD, StoreValue } from "../../App/AuthType";
import { useEffect, useState } from "react";

import { ImpersonationIcon } from "../../Icons/Impersonation";
import { ListIcon } from "../../Icons/ListIcon";
import { MfaComponent } from "../../components/MfaComponent";
import { ValidationFormForImpersonation } from "../../components/ValidationFormForImpersonation";
import { queryClient } from "../../App/AppProviders";
import { useNavigate } from "react-router";

type pageDetails = {
  pageName: string;
  pageUrl: string;
  isModel: boolean;
  roles: Array<string>;
  icon: JSX.Element;
};
export const DashboardScreen = () => {
  const navigate = useNavigate();
  const storedValue = queryClient.getQueryData("token") as StoreValue;
  const [menus, setMenus] = useState([] as pageDetails[]);
  const pageDetails: Array<pageDetails> = [
    {
      pageName: "Impersonation",
      pageUrl: "",
      isModel: true,
      roles: ["ADMIN", "FINANCE", "SUPER_ADMIN"],
      icon: <ImpersonationIcon fill="white" stroke="white" />,
    },
    {
      pageName: "Dashboard",
      pageUrl: OLD_KUNDE_DASHBOARD,
      isModel: false,
      roles: ["ADMIN", "FINANCE", "SUPER_ADMIN"],
      icon: <ListIcon fill="white" />,
    },
    // {
    //   pageName: "Price Viewer",
    //   pageUrl: "price-viewer",
    //   isModel: false,
    //   roles: ["ADMIN", "FINANCE", "SUB_ADMIN"],
    //   icon: <ListIcon fill="white" />,
    // },
    // {
    //   pageName: "Customer Overview",
    //   pageUrl: "/customeroverview",
    //   isModel: false,
    //   roles: ["ADMIN,FINANCE,SUB_ADMIN"],
    //   icon: <ListIcon fill="white" />,
    // },
  ];

  useEffect(() => {
    const result: pageDetails[] = [];

    for (let index = 0; index < pageDetails.length; index++) {
      for (let j = 0; j < storedValue.roles.length; j++) {
        if (pageDetails[index].roles.includes(storedValue.roles[j])) {
          result.push(pageDetails[index]);
        }
      }
    }
    setMenus(result);
  }, []);

  const [modalForImpersonate, setModalForImpersonate] = useState(false);
  const [modalForMfa, setModalForMfa] = useState(false);

  const closeMfaModelAndOpenTargetModel = (data: boolean) => {
    setModalForMfa(data);

    setModalForImpersonate(!data);
  };
  const closeMfaModel = (data: boolean) => {
    setModalForMfa(false);
  };

  const navigateToPage =
    (page: pageDetails) => (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault();
      if (!page.isModel) navigate(page.pageUrl);
      else setModalForMfa(true);
    };

  return (
    <>
      <Typography
        sx={{
          paddingTop: { md: "25vh", xs: "60px" },
          fontWeight: "bold",
          paddingBottom: { md: "10vh", xs: "3vh" },
        }}
        className={` mt-20 text-center`}
        variant="h3"
      >
        Welcome to Motkraft
      </Typography>
      <Stack
        className="px-6 mx-2 "
        direction={{ xs: "column", sm: "row" }}
        sx={{ paddingInline: { md: "6.25rem" } }}
        justifyContent="space-between"
        alignItems="center"
        spacing={7}
      >
        {menus.map((page: pageDetails) => {
          return (
            <Card
              style={{ boxShadow: "2px 2px 5px 0px #888888" }}
              className="w-full h-48"
              onClick={navigateToPage(page)}
              key={page.pageName}
            >
              <CardContent className="text-center">
                <div className="flex justify-center pt-3">
                  <div
                    style={{ backgroundColor: "#61D8AA" }}
                    className="p-4 rounded-full"
                  >
                    {page.icon}
                  </div>
                </div>

                <h1 className="pt-3 font-bold underline underline-offset-4">
                  {page.pageName}
                </h1>
              </CardContent>
            </Card>
          );
        })}
      </Stack>

      {modalForMfa && (
        <MfaComponent
          closeMfaModel={closeMfaModel}
          setOpenModal={closeMfaModelAndOpenTargetModel}
        />
      )}
      {modalForImpersonate && (
        <ValidationFormForImpersonation setOpenModal={setModalForImpersonate} />
      )}
    </>
  );
};
