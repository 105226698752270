import { Credentials, Token } from "./AuthType";

import { AxiosError } from "axios";
import createTokenQuery from "./tokenQuery";
import { loginUrl } from "../config/react-app-config";

const localStorageTokenKey = "token";
export const TOKEN_LIFE = 30 * 60 * 1000;
export const REFRESH_TOKEN_LIFE = 10 * 60 * 60 * 1000;

const timestampToken = (
  oauthToken: string,
  roles: Array<string>,
  time: Date
): Token => {
  return {
    authToken: oauthToken,
    roles,
    time,
  };
};
// eslint-disable-next-line @typescript-eslint/no-unused-vars

const login = async (data: Credentials): Promise<Token> => {
  const params = {
    email: data.email,
    password: data.password,
  };

  return loginUrl
    .post("/api/v1/auth/authenticate", params, {
      headers: {
        "Content-type": "application/json",
      },
    })
    .then((response) =>
      timestampToken(
        response.data.token,
        response.data.roles,
        response.data.expTime
      )
    )
    .catch((error) => {
      throw error;
    });
};

export const logout = async () => {
  auth.logout();
  window.location.replace("/login");
};

const retry = (count: number, error: AxiosError) =>
  count < 3 &&
  !(
    error.response &&
    error.response.status &&
    (error.response.status === 403 || error.response.status === 401)
  );

const auth = createTokenQuery<Token, Credentials>({
  queryKey: localStorageTokenKey,
  sendLogin: login,
  retry,
});

auth.init(TOKEN_LIFE - 60 * 1000);

export const useLogin = auth.useLogin;
export const getToken = auth.getToken;
export const authLogout = auth.logout;
export const setTokenValue = auth.setTokenValue;
