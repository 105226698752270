import { Navigate, Outlet, useLocation } from "react-router-dom";
import { RequireAuthProps, StoreValue } from "./AuthType";

import { authLogout } from "./auth";
import moment from "moment";
import { queryClient } from "./AppProviders";

export const RequireAuth = ({ allowRoles }: RequireAuthProps) => {
  const location = useLocation();
  const storedValue = queryClient.getQueryData("token") as StoreValue;
  const tokenWillExpire = moment(storedValue?.time);
  const currenTime = moment.now();
  const isTokenExpired = tokenWillExpire.isAfter(currenTime);
  if (!isTokenExpired) {
    authLogout();
  }
  if (isTokenExpired) {
    const remainingTimeInMilliseconds = tokenWillExpire.diff(
      currenTime,
      "milliseconds"
    );
    setTimeout(() => {
      authLogout();
    }, remainingTimeInMilliseconds);
  }

  return isTokenExpired ? (
    storedValue?.roles?.find((role) => allowRoles?.includes(role)) ? (
      <>
        <Outlet />
      </>
    ) : storedValue?.authToken ? (
      <Navigate to="/" state={{ from: location }} replace={true} />
    ) : (
      <Navigate to="/login" replace />
    )
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

// logged time + 1 hour if this time is greater than current time then ok
