import * as Yup from "yup";

import { Button, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router";

import { Credentials } from "../../App/AuthType";
import { ForgetPasswordContext } from "./LoginPage";
import { Form } from "../../common/Forms/Form";
import { Input } from "../../common/Forms/Input";
import { useLogin } from "../../App/auth";
import { useTranslation } from "react-i18next";

export interface LoginFormProps {
  onSubmit: (values: Credentials) => void;
  forgetPassword: () => void;
}

export const LoginForm: React.FC = () => {
  const { t } = useTranslation();
  const { requestLogin,error } = useLogin();
  const { state,dispatch } = useContext(ForgetPasswordContext);
  const navigation = useNavigate();
  const location = useLocation();
  const [emailField, setEmailField] = useState("");
  const onLogin = (data: Credentials): void => {
    requestLogin(data).then((token) => {
      let from = (location.state as any)?.from?.pathname || "/";

      if (token) {
        // window.location.replace(from);
        // return <Navigate to="/" replace={true} />;
        navigation(from, { replace: true });
      }
    });
  };
  const forgetPassword = () => {
    dispatch({
      type: "Increment",
      payload: {
        ...state,
        activeIndex: 0,
        header: t("forgot_password.forgot-your-password"),
      },
    });
  };

  const onChange = (email: string) => {
    setEmailField(email);
  };

  const LoginSchema = Yup.object().shape({
    email: Yup.string().required().email("Invalid email"),
    password: Yup.string()
      .required()
  });
  
  const initialValues: Credentials = {
    email: "",
    password: "",
  };
  return (
    <>
      <Form
        onSubmit={onLogin}
        buttonText={t("login.sign-in")}
        initialValues={initialValues}
        validationSchema={LoginSchema}
        buttonContainer={"flex ml-auto space-x-4 relative"}
      >
        <Input
          onFieldChange={onChange}
          name="email"
          label={t("login.email")}
          type="email"
        />
        <Input name="password" label={t("login.password")} type="password" />
        <div className="flex items-center">
          <div className="ml-auto text-sm">
            <Button
              variant="link"
              className="font-medium text-brand-600 hover:text-brand-500"
              onClick={forgetPassword}
            >
              <Typography
                className="font-medium text-brand-600 hover:text-brand-500"
                variant="subtitle1"
              >
                {t("login.forgot-your-password")}
              </Typography>
            </Button>
          </div>
        </div>
      </Form>
    </>
  );
};
