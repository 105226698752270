import { CommonProps } from "../../pages/OldKunde/Types";
import LabelContainer from "./Container";
import { Typography } from "@mui/material";

const PendingStatus = ({ children }: CommonProps) => {
  return (
    <LabelContainer status={"bg-pending"}>
      <Typography fontSize={14} variant="oldDashboardFont" color={"#A07914"}>
        {children}
      </Typography>
    </LabelContainer>
  );
};

export default PendingStatus;
