import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FontAwesomeIconPropsWithOption } from "../pages/OldKunde/Types";
const MailIcon = ({ size = "lg" ,...res}: FontAwesomeIconPropsWithOption) => {
  return (
    <FontAwesomeIcon
      style={{
        backgroundColor: "#EEB010",
        paddingInline: 4,
        paddingTop:3,
        paddingBottom:3,
        borderRadius: 4,
      }}
      size={size}
      color="#fff"
      icon={"envelope"}
      {...res}
    />
  );
};
export default MailIcon;
