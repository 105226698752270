import { backend } from "../../config/react-app-config";
import { getToken } from "../../App/auth";

export const isExistingCustomerFetch = async (phone: string): Promise<any> => {
  const motkraftUser = await getToken();

  return backend.get("/customer/v2/customerExists", {
    headers: {
      "Motkraft-impersonate-user-phone": phone,
      "Motkraft-user": motkraftUser!.authToken,
    },
  });
};

export const getMfa = async (): Promise<any> => {
  return backend.get("/getqrcode", { data: null });
};
export const verifyOtpforMfa = async (data: any): Promise<any> => {
  return backend.get(`api/v1/mfa/validate-code?code=${data}`);
};
export const getIsMfa = async (userId: number): Promise<any> => {
  return backend.get("/api/v1/mfa/generate-qrcode");
};
export const verifySmsOtp = async (data: any): Promise<any> => {
  return backend.post("/verifyOtp", data);
};
export const sendSmsOtp = async (data: any): Promise<any> => {
  return backend.post("/sendOtp", data);
};

export const saveAuditLogForImpersonation = (data: any) => {
  return backend.post("/api/v1/impersonate-log/save", data);
};
