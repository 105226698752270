import "@fontsource/inter";
import "@fontsource/inter/500.css";
import "@fontsource/inter/700.css";
import "./config/yup-config";
import "./index.css";
import "./utils/i18n/i18n-config";

import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { App } from "./App/App";
import { AppProviders } from "./App/AppProviders";
import { LocalizationProvider } from "@mui/x-date-pickers";
import React from "react";
import ReactDOM from "react-dom";
import { debugContextDevtool } from "react-context-devtool";
import { getEnvironmentVariable } from "./config/utils";
import { reactAppConfig } from "./config/react-app-config";
import reportWebVitals from "./reportWebVitals";

if (
  getEnvironmentVariable("NODE_ENV") === "development" &&
  !getEnvironmentVariable("REACT_APP_BASE_URL")
) {
  const { worker } = require("./mocks/browser");
  worker.start();
}
const container = document.getElementById("root");

ReactDOM.render(
  <React.StrictMode>
    <AppProviders>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <App />
      </LocalizationProvider>
    </AppProviders>
  </React.StrictMode>,
  container
);

debugContextDevtool(container, {
  disable: reactAppConfig.clientId === "production",
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
