import { Typography } from "@mui/material";
type ToolTipComponentProps = {
  title: string  | undefined;
  description?: string | undefined ;
};
const ToolTipComponent = ({ title,  description }: ToolTipComponentProps) => {
  return (
    <div className="flex flex-col">
      <Typography
        variant="oldDashboardFont"
        fontWeight={600}
        fontSize={12}
        color={"error"}
      >
        {title}
      </Typography>
      <Typography variant="oldDashboardFont" color={"#717171"} fontSize={12}>
        {description}
      </Typography>
    </div>
  );
};
export default ToolTipComponent;
