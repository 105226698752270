import { ErrorMessage, useField } from "formik";

import { Label } from "./Label";
import { useTranslation } from "react-i18next";

export interface GenericInputComponentProps {
  name: string;
  label: string;
  srOnlyLabel?: boolean;
  disabled?: boolean;
}

export interface InputProps extends GenericInputComponentProps {
  type?: "text" | "email" | "number" | "password" | "tel" | "checkbox";
  hidden?: boolean;
  onFieldChange?: (evt: any) => void;
  triggerField?: boolean;
}

export const Input: React.FC<InputProps> = ({
  name,
  label,
  type = "text",
  hidden,
  disabled,
}) => {
  const { t } = useTranslation();
  const [field, meta] = useField(name);
  const showError = meta.touched && meta.error;

  return (
    <div>
      <Label htmlFor={name}>{label}</Label>
      <div className="mt-2">
        <input
          type={type}
          id={name}
          data-testid={name}
          aria-invalid={showError ? "true" : "false"}
          disabled={hidden || disabled}
          className={`appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-brand-500 focus:border-brand-500 sm:text-sm
          ${
            showError
              ? "border-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500"
              : ""
          }
          ${disabled ? "border border-gray-400 bg-gray-200 cursor-default" : ""}
          `}
          {...field}
        />
      </div>

      <ErrorMessage name={name}>
        {(msg) => (
          <p
            className="mt-2 text-xs italic text-red-600"
            role="alert"
            data-testid={`${name}-error-message`}
          >
            {typeof msg === "string"
              ? t(msg)
              : t((msg as any).key, (msg as any).values)}
          </p>
        )}
      </ErrorMessage>
    </div>
  );
};
