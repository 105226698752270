import { backend } from "../../config/react-app-config";

export const getStatusCount = () => {
  return backend.get("/api/v1/users/getCount");
};
export const getUserOverviewTableData = async ({ queryKey }: any) => {
  const [_, page] = queryKey;
  // console.log(queryKey);
  // console.log(page?);
  return backend.get(`/api/v1/users/searchQuery?${page}`);
};
export const getUserDetailsById = (id: number) => {
  return backend.get(`/api/v1/users/${id}`);
};
export const getUserDetailsByToken = () => {
  return backend.get("api/v1/users/userDetails");
};
